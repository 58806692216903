module.exports = Object.freeze({
  SIGN_OUT: "Sign Out",
  ARE_YOU_SURE_SIGN_OUT: "Are you sure you want to sign out?",
  OVERALL_STATS: "Overall Stats",
  COMING_SOON: "Coming Soon",
  POSITIONS: "Open Positions",
  NEW_POSITION: "New Position",
  STRATEGIES: "Strategies",
  RECOMMENDED_STRATEGIES: "Recommended Strategies",
  TUTORIALS: "Tutorials",
  ALL_STRATEGIES: "All Strategies",
});
