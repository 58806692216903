import { Chip, Typography } from "@mui/material";
import CurrencyAvatar from "./CurrencyAvatar";

function ImageChip({ src, label, hoverText, ...rest }) {
  return (
    <Chip
      size="small"
      icon={<CurrencyAvatar src={src} label={hoverText || label} />}
      label={(
        <Typography variant="overline">
          {label}
        </Typography>
      )}
      {...rest}
    />
  );
}
export default ImageChip;
