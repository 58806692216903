import { Button, CircularProgress } from "@mui/material";

function FormikButton({ formik, disabled, children, ...rest }) {
  return (
    <Button
      endIcon={formik.isSubmitting && (
        <CircularProgress size={12} />
      )}
      disabled={formik.isSubmitting || disabled}
      onClick={formik.handleSubmit}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default FormikButton;
