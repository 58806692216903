import { getPositionAt } from "@rather-labs/vaults-unifarming-sdk";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { MAP_NETWORK_TO_RPC } from "@rather-labs/vaults-unifarming-sdk/constants";

function usePosition(address, network, protocol = "sushiswap", farm = "masterchefv2") {
  const [position, setPosition] = useState(null);
  
  useEffect(() => {
    if (address) {
      const jsonRpcUrl = MAP_NETWORK_TO_RPC[network];
      const provider = new ethers.providers.JsonRpcProvider(jsonRpcUrl);
      getPositionAt({
        positionAddress: address,
        provider,
        protocol,
        farm,
      }).then(setPosition);
    }
  }, [address, network, protocol, farm]);

  return position;
}

export default usePosition;
