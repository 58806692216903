import { Button, ButtonGroup, CircularProgress, Divider, Paper, Stack, styled, Tooltip, Typography } from "@mui/material";
import { amber, lightBlue } from "@mui/material/colors";
import AddBeefyLiquidityDialog from "components/Dialogs/beefy/AddBeefyLiquidityDialog";
import ConfirmTransactionDialog from "components/Dialogs/ConfirmTransactionDialog";
import DepositTokenDialog from "components/Dialogs/DepositTokenDialog";
import WithdrawTokenDialog from "components/Dialogs/WithdrawTokenDialog";
import CurrencyAvatar from "components/CurrencyAvatar";
import { forwardRef, useEffect, useState } from "react";
import { useDialogs } from "store/Dialogs";
import { metamask } from "connectors/metamask";
import { walletconnect } from "connectors/walletconnect";
import useWeb3 from "hooks/useWeb3";
import { MAP_NETWORK_TO_RPC } from "@rather-labs/vaults-unifarming-sdk/constants";
import { ethers } from "@rather-labs/vaults-unifarming-sdk";

const mapNetworkToExplorer = {
  ethereum: "https://etherscan.io",
  polygon: "https://polygonscan.com",
  celo: "https://explorer.celo.org",
  bsc: "https://bscscan.com",
  fantom: "https://ftmscan.com/",
};

const mapNetworkToChainId = {
  ethereum: 1,
  polygon: 137,
  celo: 42220,
  fuse: 122,
  bsc: 56,
  fantom: 250,
};

const MetamaskButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: amber[800],
  "&:hover": {
    backgroundColor: amber[900],
  },
}));

const WalletConnectButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: lightBlue[500],
  "&:hover": {
    backgroundColor: lightBlue[600],
  },
}));

function BeefyPositionActions({ position, instance, metrics, handleResult, ...rest }, ref) {
  const [disLoading, setDisLoading] = useState(false);
  const [permissions, setPermissions] = useState({});

  const dialogs = useDialogs();
  const { address, signer } = useWeb3();

  const { idleBalance, marketValue: underlyingBalance } = metrics;

  const fetchPermissions = async () => {
    const [
      canAssemble,
      canDisassemble,
      isLiquidityOwner,
    ] = await Promise.all([
      instance.position.hasRole(ethers.utils.id("ASSEMBLER"), address),
      instance.position.hasRole(ethers.utils.id("DISASSEMBLER"), address),
      instance.position.hasRole(ethers.utils.id("LIQUIDITY_OWNER"), address),
    ]);
    setPermissions({
      canAssemble: canAssemble || isLiquidityOwner,
      canDisassemble: canDisassemble || isLiquidityOwner,
      canWithdraw: isLiquidityOwner,
    });
  };

  useEffect(() => {
    if (address) {
      fetchPermissions();
    }
  }, [address]);

  const addBalance = async (token) => {
    dialogs.create({
      DialogType: DepositTokenDialog,
      wallet: address,
      signer,
      instance,
      token,
      onResult: handleResult,
    });
  };

  const withdraw = async (token) => {
    dialogs.create({
      DialogType: WithdrawTokenDialog,
      wallet: address,
      signer,
      instance,
      token,
      onResult: handleResult,
    });
  };

  const assemble = async () => {
    dialogs.create({
      DialogType: AddBeefyLiquidityDialog,
      metrics,
      signer,
      wallet: address,
      instance,
      onResult: handleResult,
    });
  };

  const disassemble = async () => {
    setDisLoading(true);
    const tx = await instance.buildDisassembleTransaction();
    tx.from = address;
    try {
      const transaction = await signer.sendTransaction(tx);
      dialogs.create({
        DialogType: ConfirmTransactionDialog,
        provider: instance.provider,
        txHash: transaction.hash,
        onResult: handleResult,
      });
    } catch (e) {
      console.log(e);
    }
    setDisLoading(false);
  };

  const connectWalletWithMetamask = async () => {
    await metamask.activate({
      chainId: mapNetworkToChainId[position.network],
      rpcUrls: [MAP_NETWORK_TO_RPC[position.network]],
      chainName: position.network.toUpperCase(),
      blockExplorerUrls: [mapNetworkToExplorer[position.network]],
    });
  };

  const connectWalletWithWalletConnect = async () => {
    await walletconnect.activate(mapNetworkToChainId[position.network] || undefined);
  };

  if (!address) {
    return (
      <Paper sx={{ padding: 2, height: "100%" }} ref={ref} {...rest}>
        <Stack justifyContent="space-between" height="100%">
          <Typography textAlign="center" fontWeight="bold">
            Connect your wallet to fund or withdraw from your position
          </Typography>
          <Stack gap={2}>
            <MetamaskButton variant="contained" onClick={connectWalletWithMetamask}>
              Connect Metamask
            </MetamaskButton>
            <WalletConnectButton variant="contained" onClick={connectWalletWithWalletConnect}>
              WalletConnect
            </WalletConnectButton>
          </Stack>
        </Stack>
      </Paper>
    );
  }

  return (
    <Paper sx={{ padding: 2, height: "100%" }} ref={ref} {...rest}>
      <Stack spacing={2} justifyContent="flex-end">
        <Stack spacing={1}>
          <Typography variant="h6" fontWeight="bold">
            Idle Balance
          </Typography>

          {position.strategy.tokens.map((token) => (
            <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
              <Stack gap={1} direction="row" alignItems="center">
                <CurrencyAvatar src={token.icon} label={token.name} />
                <Typography variant="body2">
                  {idleBalance.scaleDown(token.decimals).toFixed(2)}
                  {" "}
                  <strong>{token.symbol}</strong>
                </Typography>
              </Stack>
              <ButtonGroup color="secondary" size="small" variant="contained">
                <Button onClick={() => addBalance(token)}>Deposit</Button>
                <Tooltip title="Your account cannot withdraw funds from this position" hidden={permissions.canWithdraw}>
                  <Button
                    disabled={!permissions.canWithdraw}
                    onClick={() => withdraw(token)}
                  >
                    Withdraw
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Stack>
          ))}

          <Tooltip title={permissions.canAssemble ? "" : "Your account cannot invest funds from this position"}>
            <span>
              <Button
                fullWidth
                disabled={disLoading || !permissions.canAssemble}
                endIcon={disLoading && (
                  <CircularProgress size={12} />
                )}
                variant="outlined"
                color="secondary"
                onClick={assemble}
              >
                Invest
              </Button>
            </span>
          </Tooltip>
        </Stack>

        <Divider variant="inset" />

        <Stack gap={1}>
          <Typography variant="h6" fontWeight="bold">
            Invested Balance
          </Typography>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="body2">
              <strong>$</strong>
              {underlyingBalance.toFixed(2)}
            </Typography>
          </Stack>
          <Tooltip title={permissions.canDisassemble ? "" : "Your account cannot disassemble this position"}>
            <span>
              <Button
                disabled={disLoading || !permissions.canDisassemble}
                endIcon={disLoading && (
                  <CircularProgress size={12} />
                )}
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={disassemble}
              >
                Disassemble
              </Button>
            </span>
          </Tooltip>
        </Stack>

      </Stack>
    </Paper>
  );
}

export default forwardRef(BeefyPositionActions);
