module.exports = Object.freeze({
  PRESIGN_URL: { method: "POST", url: "/assets/pre-signed-urls" },
  SESSION: {
    REFRESH: { method: "POST", url: "/sessions/tokens", authentication: true },
    GET: (token) => ({ method: "get", url: `/sessions/${token}` }),
    CREATE: {
      WITH_LOCAL: { method: "POST", url: "/sessions/local-strategy" },
    },
  },
  USER: {
    UPDATE: { method: "PATCH", url: "/users/me" },
  },
  POSITIONS: {
    GET: { method: "GET", url: "/positions" },
    GET_BY_ID: (id) => ({ method: "GET", url: `/positions/${id}` }),
    UPDATE_SETTINGS: (id) => ({ method: "PATCH", url: `/positions/${id}/settings` }),
    REQUEST: { method: "POST", url: "/positions/requests" },
  },
  STRATEGIES: {
    GET: { method: "GET", url: "/strategies" },
    GET_RECOMMENDED: { method: "GET", url: "/strategies/recommended" },
  },
  NETWORKS: {
    GET: { method: "GET", url: "/networks" },
  },
  STATS: {
    GET_BY_STRATEGY_ID: (id) => ({ method: "GET", url: `/stats/${id}` }),
  },
});
