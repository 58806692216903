import React, { useEffect, useRef, useState } from "react";
import IERC20ABI from "@rather-labs/vaults-unifarming-sdk/contracts/IERC20.json";
import { BN } from "@rather-labs/vaults-unifarming-sdk";
import { toBN } from "@rather-labs/vaults-unifarming-sdk/lib/utils";
import { ethers } from "ethers";
import { Button, Stack, TextField, Typography } from "@mui/material";
import AlertContainer from "components/AlertContainer";
import { useDialogs } from "store/Dialogs";
import DialogForm from "./DialogForm";
import ConfirmTransactionDialog from "./ConfirmTransactionDialog";

function WithdrawTokenDialog({
  instance,
  signer,
  onClose,
  token,
  onResult,
  wallet,
  ...rest
}) {
  const [balance, setBalance] = useState();
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [to, setTo] = useState("");
  const alertContainer = useRef();
  const dialogs = useDialogs();

  const { provider } = instance;
  useEffect(() => {
    (async () => {
      const IERC20 = new ethers.Contract(token.address, IERC20ABI.abi, provider);
      const balance = await IERC20.balanceOf(instance.address);
      setBalance(toBN(balance).scaleDown(token.decimals));
    })();
  }, []);

  const withdraw = async () => {
    const parsedAmount = BN(amount);
    if (!ethers.utils.isAddress(to)) {
      alertContainer.current.setAlert({ severity: "error", text: "Invalid address" });
    }
    if (parsedAmount.gt(balance)) {
      alertContainer.current.setAlert({ severity: "error", text: "Amount greater than balance" });
    }
    
    const tx = await instance.buildWithdrawTransaction({ asset: token.address, amount: parsedAmount.scaleUp(token.decimals), to });
    tx.from = wallet;

    setLoading(true);
    try {
      const transaction = await signer.sendTransaction(tx);
      dialogs.create({
        DialogType: ConfirmTransactionDialog,
        txHash: transaction.hash,
        provider,
        onResult,
      });
      onClose();
    } catch (e) {
      console.log(e);
      setLoading(true);
    }
  };

  return (
    <DialogForm
      cross
      onClose={onClose}
      title={`Withdraw idle ${token.symbol.toUpperCase()} to an account`}
      actions={[
        <Button
          onClick={withdraw}
          variant="contained"
          disabled={loading}
          color="secondary"
        >
          Withdraw
        </Button>,
      ]}
      {...rest}
    >
      <Stack spacing={2}>
        <TextField
          value={to}
          placeholder="0x0000000...."
          label="Address"
          type="text"
          onChange={({ target: { value } }) => setTo(value)}
        />
        <Stack>
          <TextField
            type="number"
            label="Amount"
            value={amount}
            onChange={({ target: { value } }) => setAmount(value)}
            InputProps={{
              endAdornment: (
                <Button
                  size="small"
                  onClick={() => setAmount(balance.toString())}
                >
                  MAX
                </Button>
              ),
            }}
          />
          <Typography variant="caption" fontWeight="bold">
            {`Balance: ${balance ? balance.toFixed().toString() : "-"} ${token.symbol}`}
          </Typography>
        </Stack>
      </Stack>

      <AlertContainer ref={alertContainer} />
    </DialogForm>
  );
}

export default WithdrawTokenDialog;
