import { Paper, Stack, Typography } from "@mui/material";

function StatPaper({ label, value }) {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: 1,
        background: "#D4DFF7",
        color: "secondary.main",
      }}
    >
      <Stack direction="row" gap={1}>
        <Typography fontWeight="bold">
          {label}
        </Typography>
        <Typography fontWeight="bold" color="#00AC70">
          {value}
        </Typography>
      </Stack>
    </Paper>
  );
}

export default StatPaper;
