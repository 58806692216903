import { Divider } from "@mui/material";

function PinkDivider({ sx, ...rest }) {
  return (
    <Divider
      sx={{
        backgroundColor: "primary.light",
        borderWidth: 1.5,
        borderRadius: 8,
        ...sx,
      }}
      {...rest}
    />
  );
}

export default PinkDivider;
