import { useEffect, useState, useCallback } from "react";
// import { Avatar, ListItemAvatar, ListItemText, Stack, styled, TextField } from "@mui/material";
import { ListItemText, Stack, styled, TextField } from "@mui/material";
import FadeInDelay from "components/FadeInDelay";
import { ListOutlined, Search } from "@mui/icons-material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";

import SectionTitle from "components/SectionTitle";
import useTranslation from "hooks/useTranslation";
import APIEndpoints from "APIEndpoints";
import useResource from "hooks/useResource";
import useDebounce from "hooks/useDebounce";
import useBeefyrowingStats from "hooks/useBeefyrowingStats";
import SkeletonList from "components/SkeletonList";
import UnifarmingStrategyItem from "./UnifarmingStrategyItem";
import BeefyrowingStrategyItem from "./BeefyrowingStrategyItem";

const VerticalStack = styled("div")(({ theme }) => ({
  overflowX: "hidden",
  overflowY: "auto",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  padding: theme.spacing(1),
  gap: theme.spacing(2),
  maxHeight: 350,
}));

export default function AllStrategies({ onClick, selectedStrategy }) {
  const { t } = useTranslation();
  const { data } = useResource(APIEndpoints.STRATEGIES.GET);
  const { data: networks } = useResource(APIEndpoints.NETWORKS.GET);
  const [filteredResults, setFilteredResults] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState("all");
  const debouncedSearch = useDebounce(search);
  const [beefyStrat, setBeefyStrat] = useState(null);
  const { roi: beefyrowingRoi, tvl: beefyrowingTvl } = useBeefyrowingStats(beefyStrat);

  // Beefyrowing patch: calculate APR after receiving data
  useEffect(() => {
    if (data) {
      setBeefyStrat(data.find((strat) => (strat.type === "beefyrowing")));
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (debouncedSearch || (selectedNetwork !== "all")) {
        setFilteredResults(data.filter((strat) => {
          const networkFilter = (selectedNetwork === "all") || ((selectedNetwork !== "all") && (strat.network.id === selectedNetwork));
          const searchFilter = !debouncedSearch || strat.name.match(new RegExp(debouncedSearch, "i"));
          return networkFilter && searchFilter;
        }));
      } else {
        setFilteredResults(data);
      }
    }
  }, [debouncedSearch, data, selectedNetwork]);

  const handleChainChange = (e) => {
    setSelectedNetwork(e.target.value);
  };

  const getStrategyItem = useCallback((strategy) => {
    let strategyItem;
    let clonedStrategy;
    switch (strategy.type) {
    case "unifarming":
      strategyItem = (
        <UnifarmingStrategyItem
          selected={selectedStrategy?.id === strategy.id}
          onClick={onClick}
          data={strategy}
          key={strategy.id}
        />
      );
      break;
    case "beefyrowing":
      clonedStrategy = _.cloneDeep(strategy);
      clonedStrategy.lastStat = {
        values: {
          roiPerYear: beefyrowingRoi,
          tvl: beefyrowingTvl,
        },
      };
      strategyItem = (
        <BeefyrowingStrategyItem
          selected={selectedStrategy?.id === strategy.id}
          onClick={onClick}
          data={clonedStrategy}
          key={strategy.id}
        />
      );
      break;
    default:
      break;
    }
    return strategyItem;
  }, [selectedStrategy, onClick, beefyrowingRoi, beefyrowingTvl]);

  return (
    <FadeInDelay mountOnEnter delay={200}>
      <Stack gap={1}>
        <Stack direction="row" justifyContent="space-between">
          <SectionTitle Icon={ListOutlined}>
            {t("ALL_STRATEGIES")}
          </SectionTitle>
          <Stack direction="row" justifyContent="right" gap={1}>
            <Box sx={{ minWidth: 130 }}>
              <FormControl fullWidth size="small">
                <InputLabel>Blockchain</InputLabel>
                <Select
                  label="Blockchain"
                  value={selectedNetwork}
                  onChange={handleChainChange}
                >
                  <MenuItem value="all">ALL</MenuItem>
                  {networks?.map((network) => (
                    <MenuItem value={network.id}>
                      <Stack direction="row" sx={{ display: "flex", algintItems: "right" }}>
                        {/* <ListItemAvatar>
                          <Avatar
                            src={network.icon}
                            sx={{ width: 20, height: 20 }}
                          />
                        </ListItemAvatar> */}
                        <ListItemText>{network.name.toUpperCase()}</ListItemText>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <TextField
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: <Search />,
              }}
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </Stack>
        </Stack>
        <VerticalStack>
          {!filteredResults && <SkeletonList Component={UnifarmingStrategyItem.Skeleton} />}
          {filteredResults && filteredResults.map((strategy) => getStrategyItem(strategy))}
        </VerticalStack>
      </Stack>
    </FadeInDelay>
  );
}
