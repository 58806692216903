import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import { List, Typography } from "@mui/material";
import useResource from "hooks/useResource";
import querystring from "querystring";
import FadeInDelay from "./FadeInDelay";

function RemoteList({ endpoint, paginated, filters, onClick, selected, onChange, Item, Skeleton, Container, ...rest }, ref) {
  const computedEndpoint = useMemo(() => ({
    ...endpoint,
    url: `${endpoint.url}?${querystring.stringify(filters)}`,
  }), [endpoint, filters]);

  const { data, mutate } = useResource(computedEndpoint);
  const elements = data && (paginated ? data.rows : data);

  const ContainerComponent = Container || List;

  useEffect(() => {
    if (data && onChange) onChange(data);
  }, [data, onChange]);

  useImperativeHandle(ref, () => ({
    refresh: () => mutate(data),
  }));

  return (
    <ContainerComponent {...rest} ref={ref}>
      {data
        ? elements.map((item, index) => (
          index < 10
            ? (
              <FadeInDelay delay={100 * (index + 1)}>
                <Item
                  key={item.id}
                  data={item}
                  onClick={onClick}
                  selected={item.id === selected}
                />
              </FadeInDelay>
            )
            : (
              <Item
                key={item.id}
                onClick={onClick}
                selected={item.id === selected}
                data={item}
              />
            )
        ))
        : Array(3).fill().map(() => (Skeleton ? (
          <Skeleton />
        )
          : null
        ))}
      {data && !elements.length && (
        <Typography color="textSecondary">
          No results found
        </Typography>
      )}
    </ContainerComponent>
  );
}

export default forwardRef(RemoteList);
