import useSession from "hooks/useSession";
import hit from "hit";
import { useCallback } from "react";

const useHit = () => {
  const { data } = useSession();
  return useCallback(
    (endpoint, body, headers) => hit(
      endpoint,
      body,
      {
        ...(data && { Authorization: data.accessToken }),
        ...headers,
      },
    )
    , [data],
  );
};

export default useHit;
