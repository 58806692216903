import { BrowserRouter } from "react-router-dom";

const withRouter = (Component) => function (props) {
  return (
    <BrowserRouter>
      <Component {...props} />
    </BrowserRouter>
  );
};

export default withRouter;
