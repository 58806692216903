import React from "react";
import { Container, Box, Typography, Stack, Grid, Hidden, Fade } from "@mui/material";
import PinkDivider from "components/PinkDivider";
import { useFormik } from "formik";
import * as yup from "yup";
import useHit from "hooks/useHit";
import FormikTextField from "components/FormikTextField";
import APIEndpoints from "APIEndpoints";
import useSession from "hooks/useSession";
import FormikResponseError from "components/FormikResponseError";
import FormikButton from "components/FormikButton";
import LinesAnimation from "lottie-files/lines.json";
import DotsAnimation from "lottie-files/dots.json";
import Lottie from "react-lottie";

const validationSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

function SignIn() {
  const hit = useHit();
  const session = useSession();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (data) => {
      const newSession = await hit(APIEndpoints.SESSION.CREATE.WITH_LOCAL, data);
      if (!newSession.error) {
        localStorage.setItem("session", JSON.stringify(newSession));
        session.refresh();
      } else {
        formik.setFieldError("response", newSession.error);
      }
    },
  });

  return (
    <Grid
      container
      spacing={0}
      sx={{
        height: "100%",
      }}
    >
      <Hidden mdDown>
        <Grid
          item
          md={7}
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "secondary.dark",
          }}
        >
          <Fade in>
            <img
              src="/background_curves.svg"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: 0,
              }}
            />
          </Fade>
          <Lottie
            options={{
              autoplay: true,
              loop: true,
              animationData: LinesAnimation,
            }}
            width={200}
            height={200}
            isClickToPauseDisabled
            isStopped={false}
            isPaused={false}
            style={{
              position: "absolute",
              left: 16,
              top: 16,
            }}
          />

          <Stack alignItems="center" spacing={2} className="fade-in">
            <img
              src="/logo.svg"
              style={{
                width: 100,
              }}
            />
            <Typography
              color="white"
              variant="h4"
              className="bold"
            >
              Private Vaults
            </Typography>
          </Stack>
          
          <Lottie
            options={{
              autoplay: true,
              loop: true,
              animationData: DotsAnimation,
            }}
            isClickToPauseDisabled
            isStopped={false}
            isPaused={false}
            height={25}
            width={300}
            style={{
              position: "absolute",
              right: 16,
              bottom: 16,
            }}
          />
        </Grid>
      </Hidden>
      <Grid item md={5} xs={12}>
        <Container
          maxWidth="sm"
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
        
          <Stack component="form" onSubmit={formik.handleSubmit} spacing={3} width="100%" className="fade-in">
            <Stack spacing={1} display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h4" fontWeight="bold">
                Sign In
              </Typography>
              <PinkDivider variant="middle" sx={{ width: 50 }} />
            </Stack>

            <Stack spacing={2}>
              <FormikTextField
                formik={formik}
                name="email"
                variant="outlined"
              />
              <FormikTextField
                formik={formik}
                name="password"
                type="password"
                variant="outlined"
              />
            </Stack>

            <Typography
              variant="caption"
              color="textSecondary"
              
            >
              Forgot your password?
            </Typography>

            <Box>
              <FormikResponseError formik={formik} />
              <FormikButton
                type="submit"
                formik={formik}
                color="secondary"
                variant="contained"
                size="large"
              >
                Continue
              </FormikButton>
            </Box>
          </Stack>
        </Container>
      </Grid>
    </Grid>
  );
}

export default SignIn;
