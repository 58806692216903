import { Button } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useCallback, useRef } from "react";

const withToasts = (Component) => function (props) {
  const notistackRef = useRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const DismissButton = useCallback((key) => (
    <Button onClick={onClickDismiss(key)}>
      Dismiss
    </Button>
  ));

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      autoHideDuration={3500}
      ref={notistackRef}
      action={DismissButton}
    >
      <Component {...props} />
    </SnackbarProvider>
  );
};

export default withToasts;
