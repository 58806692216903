import { TextField } from "@mui/material";
import { capitalize } from "lodash";

function FormikTextField({ formik, name, label, ...rest }) {
  return (
    <TextField
      id={name}
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && !!formik.errors[name]}
      helperText={formik.touched[name] && formik.errors[name]}
      label={label ?? capitalize(name)}
      {...rest}
    />
  );
}

export default FormikTextField;
