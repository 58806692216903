import { Container, CircularProgress } from "@mui/material";
import APIEndpoints from "APIEndpoints";
import usePositions from "hooks/usePositions";
import useResource from "hooks/useResource";
import { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { BN } from "@rather-labs/vaults-unifarming-sdk";
import { CHAINID_TO_NETWORK_NAME } from "@rather-labs/vaults-unifarming-sdk/constants";
import { getTokenPriceAtNet } from "lib/graph/fetchers";

const getCompositionAssets = (comp) => Object.keys(comp);

const getCompositionData = (comp) => Object.keys(comp).map((key) => {
  if (comp[key]) {
    return comp[key].toNumber();
  }
  return 0;
});

const pricesInUSD = async (position) => {
  const { addressA, addressB, symbolA, symbolB, provider } = position;
  const { chainId } = provider.network;
  const network = CHAINID_TO_NETWORK_NAME[chainId];

  const priceA = await getTokenPriceAtNet(network, { id: addressA.toLowerCase() });
  const priceB = await getTokenPriceAtNet(network, { id: addressB.toLowerCase() });

  return {
    [symbolA]: priceA,
    [symbolB]: priceB,
  };
};

function AssetComposition() {
  const { data } = useResource(APIEndpoints.POSITIONS.GET);
  const positions = usePositions(data);

  const [composition, setComposition] = useState({});
  const [ready, setReady] = useState(false);

  const updateCompositions = async (positions) => {
    // For each position, get its balance and USD price
    const balances = await Promise.all(positions.map((position) => position.getUnderlyingBalance()));
    const prices = await Promise.all(positions.map((position) => pricesInUSD(position)));

    const newComposition = {};
    for (let index = 0; index < positions.length; index += 1) {
      const position = positions[index];
      const balance = balances[index];
      const tokenPrice = prices[index];

      Object.entries(balance).forEach(([symbol, amount]) => {
        const tokenAddress = position.networkConstants.TOKEN_SYMBOL_TO_ADDRESS[symbol].toLowerCase();
        const decimals = position.networkConstants.TOKEN_ADDRESS_TO_DECIMALS[tokenAddress];
        const prevAmount = composition[symbol] || BN(0);
        const balanceNumber = amount.scaleDown(decimals).times(tokenPrice[symbol]);
        newComposition[symbol] = prevAmount.plus(balanceNumber);
      });
    }

    // Save composition value
    setComposition(newComposition);
    setReady(true);
  };

  useEffect(() => {
    if (positions) updateCompositions(positions);
  }, [positions]);

  return (
    (
      (ready)
        ? (
          <Doughnut
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                tooltip: true,
                legend: {
                  display: true,
                  position: "top",
                },
              },
            }}
            data={{
              labels: getCompositionAssets(composition),
              datasets: [{
                data: getCompositionData(composition),
                backgroundColor: [
                  "#0f1b3d",
                  "#2d378c",
                  "#4d57ab",
                  "#203163",
                ],
                hoverOffset: 4,
              }],
            }}
          />
        )
        : (
          <Container
            align="center"
            style={{ position: "relative", top: "50%", transform: "translateY(-50%)" }}
          >
            <CircularProgress
              size={120}
            />
          </Container>
        )
    )
  );
}

export default AssetComposition;
