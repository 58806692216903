import { WorkOutlineOutlined } from "@mui/icons-material";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { BN } from "@rather-labs/vaults-unifarming-sdk";
import AssetGroup from "components/AssetGroup";
import ImageChip from "components/ImageChip";
import SectionTitle from "components/SectionTitle";

function OverallInfo({ strategy }) {
  return (
    <Paper sx={{ padding: 2, height: "100%" }}>
      <Stack gap={2}>
        <SectionTitle Icon={WorkOutlineOutlined} variant="h6">
          Overall Info
        </SectionTitle>
        
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={1}>
            <AssetGroup assets={strategy.tokens} />
            <Typography variant="overline" fontWeight="bold">
              {strategy.tokens.map((token) => token.symbol).join("-").toUpperCase()}
            </Typography>
          </Stack>
          <ImageChip src={strategy.network.icon} label={strategy.network.name} />
        </Stack>
        
        <Divider variant="middle" />

        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="bold" variant="h6">
            APR
          </Typography>
          <Stack textAlign="right">
            <Typography variant="h6" color="#00AC70" fontWeight="bold">
              {BN(strategy.lastStat.values.roiPerYear).times(100).toFixed(1)}
              %
            </Typography>
            <Stack direction="row">
              <Typography variant="caption" color="textSecondary">
                Compounded rewards
              </Typography>
              <AssetGroup assets={strategy.rewardTokens} />
            </Stack>
          </Stack>
        </Stack>

        <Divider variant="middle" />

        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="bold" variant="h6">
            TVL
          </Typography>
          <Typography variant="h6" color="textSecondary">
            $
            {BN(strategy.lastStat.values.tvl).toFormat(0)}
          </Typography>
        </Stack>

      </Stack>
    </Paper>
        
  );
}

export default OverallInfo;
