import { Avatar, Tooltip } from "@mui/material";

function CurrencyAvatar({ src, label, size }) {
  return (
    <Tooltip title={label}>
      <Avatar
        src={src}
        sx={{ height: size || 18, width: size || 18 }}
      >
        {label?.charAt(0)}
      </Avatar>
    </Tooltip>
  );
}

export default CurrencyAvatar;
