import { Box, LinearProgress, Stack, Typography } from "@mui/material";

function SplashLoading({ label }) {
  return (
    <Box
      height="100%"
      width="100%"
      justifyContent="center"
      display="flex"
      alignItems="center"
    >
      <Stack spacing={1} textAlign="center">
        <Typography
          variant="h5"
          fontWeight="bold"
        >
          Loading
        </Typography>
        {label && (
          <Typography variant="caption">
            {label}
          </Typography>
        )}
        <LinearProgress />
      </Stack>

    </Box>
  );
}
export default SplashLoading;
