import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import en from "translations/en.js";
import es from "translations/es.js";

const mapCodeToDictionary = {
  1: es,
  0: en,
};

export const I18nContext = createContext({
  t: () => {},
  language: "",
  setLanguage: () => {},
});

export function I18nProvider({ children }) {
  const defaultLanguage = 0; // espaniol
  const [language, setLanguage] = useState(defaultLanguage);
  const t = useCallback((key) => mapCodeToDictionary[language][key], [language]);
  const context = useMemo(() => ({ language, setLanguage, t }), [language, t]);

  useEffect(() => {
  }, [language]);
  
  return (
    <I18nContext.Provider value={context}>
      {children}
    </I18nContext.Provider>
  );
}
