import { AvatarGroup } from "@mui/material";
import CurrencyAvatar from "./CurrencyAvatar";

function AssetGroup({ assets, size }) {
  return (
    <AvatarGroup>
      {assets.map((asset) => (
        <CurrencyAvatar src={asset.icon} size={size} label={asset.name} />
      ))}
    </AvatarGroup>
  );
}

export default AssetGroup;
