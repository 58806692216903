import { request } from "graphql-request";

// @ts-ignore TYPE NEEDS FIXING
const pager = async (endpoint, query, variables = {}) => {
  if (endpoint.includes("undefined")) return {};

  const data = {};
  let skip = 0;
  let flag = true;

  while (flag) {
    flag = false;
    /* eslint-disable no-await-in-loop */
    const req = await request(endpoint, query, variables); // await in loop needed because looping or not depends on the result

    Object.keys(req).forEach((key) => {
      data[key] = data[key] ? [...data[key], ...req[key]] : req[key];
    });

    flag = Object.values(req).reduce((prev, curr) => prev || (curr.length === 1000), false);

    // @ts-ignore TYPE NEEDS FIXING
    if (Object.keys(variables).includes("first") && variables.first !== undefined) break;

    skip += 1000;
    /* eslint-disable no-param-reassign */
    variables = { ...variables, skip };
  }

  return data;
};

export default pager;
