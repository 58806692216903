import { useRef } from "react";
import { Stack, styled } from "@mui/material";
import RemoteList from "components/RemoteList";
import FadeInDelay from "components/FadeInDelay";
import { BoltOutlined } from "@mui/icons-material";
import SectionTitle from "components/SectionTitle";
import useTranslation from "hooks/useTranslation";
import APIEndpoints from "APIEndpoints";
import UnifarmingStrategyItem from "./UnifarmingStrategyItem";

const HorizontalStack = styled("div")(({ theme }) => ({
  overflowX: "auto",
  display: "flex",
  flexDirection: "row",
  padding: theme.spacing(1),
  gap: theme.spacing(2),
}));

export default function RecommendedStrategies({ onClick, onElements, selectedStrategy }) {
  const scrollableRef = useRef();
  const { t } = useTranslation();

  return (
    <FadeInDelay mountOnEnter delay={200}>
      <Stack gap={1}>
        <SectionTitle Icon={BoltOutlined}>
          {t("RECOMMENDED_STRATEGIES")}
        </SectionTitle>
        <RemoteList
          ref={scrollableRef}
          onChange={onElements}
          endpoint={APIEndpoints.STRATEGIES.GET_RECOMMENDED}
          Container={HorizontalStack}
          Skeleton={UnifarmingStrategyItem.Skeleton}
          Item={UnifarmingStrategyItem}
          onClick={onClick}
          selected={selectedStrategy?.id}
          minHeight={180}
        />
      </Stack>
    </FadeInDelay>
  );
}
