import React from "react";
import { useHistory } from "react-router-dom";

const Navigate = ({ children, path, afterClick }) => {
  const history = useHistory();

  return React.cloneElement(
    children,
    {
      onClick: () => {
        history.push(path);
        if (afterClick) afterClick();
      },
    },
  );
};

export default Navigate;
