import React, { useRef, useState } from "react";
import { BN } from "@rather-labs/vaults-unifarming-sdk";
import { Button, Stack, TextField, Typography } from "@mui/material";
import AlertContainer from "components/AlertContainer";
import { useDialogs } from "store/Dialogs";
import DialogForm from "../DialogForm";
import ConfirmTransactionDialog from "../ConfirmTransactionDialog";

function AddBeefyLiquidityDialog({
  instance,
  metrics,
  signer,
  onClose,
  onResult,
  wallet,
  ...rest
}) {
  const [amount, setAmount] = useState(BN(0));
  const [loading, setLoading] = useState(false);
  const alertContainer = useRef();
  const dialogs = useDialogs();

  const { idleBalance } = metrics;
  const { provider, supplySymbol, supplyDecimals } = instance;

  const scaledIdleBalance = idleBalance.scaleDown(supplyDecimals);

  const assemble = async () => {
    if (amount.eq(0)) {
      alertContainer.current.setAlert({ severity: "error", text: `Add more than 0 ${supplySymbol}` });
      return;
    }
    if (amount.gt(scaledIdleBalance)) {
      alertContainer.current.setAlert({ severity: "error", text: `Not enough ${supplySymbol}` });
      return;
    }
    alertContainer.current.clear();

    setLoading(true);

    const tx = await instance.position.populateTransaction.openPosition(amount.toString().concat("0".repeat(supplyDecimals)));
    tx.from = wallet;

    try {
      const transaction = await signer.sendTransaction(tx);
      dialogs.create({
        DialogType: ConfirmTransactionDialog,
        txHash: transaction.hash,
        provider,
        onResult,
      });
      onClose();
    } catch (e) {
      console.log(e);
      //
    } finally {
      setLoading(false);
    }
  };

  const setMaxAmount = () => {
    setAmount(scaledIdleBalance);
  };

  return (
    <DialogForm
      cross
      onClose={onClose}
      title={`Deploy ${supplySymbol} to your position`}
      actions={[
        <Button
          onClick={assemble}
          variant="contained"
          disabled={loading}
          color="secondary"
        >
          Deposit
        </Button>,
      ]}
      {...rest}
    >
      <Stack spacing={2} mt={1}>
        <Stack>
          <TextField
            type="number"
            value={amount.toString()}
            label={supplySymbol}
            onChange={(e) => setAmount(BN(e.target.value))}
            InputProps={{
              endAdornment: (
                <Button
                  size="small"
                  onClick={setMaxAmount}
                >
                  MAX
                </Button>
              ),
            }}
          />
          <Typography variant="caption" fontWeight="bold">
            {`Balance: ${scaledIdleBalance} ${supplySymbol}`}
          </Typography>
        </Stack>
        <AlertContainer ref={alertContainer} />
      </Stack>
    </DialogForm>
  );
}

export default AddBeefyLiquidityDialog;
