import { Route, Redirect, matchPath, useLocation } from "react-router-dom";

import useSession from "hooks/useSession";
import { APP, AUTH } from "routes";

const REDIRECT_ALERTS = {
  [APP.NEW_POSITION]: "Before creating a position, you must sign in",
};

function DefaultSkeleton() {
  return null;
}
function AppRoute({
  withSession, withNoSession, location, Skeleton, ...rest
}) {
  const { isLoggedIn, data: session } = useSession();
  const SkeletonComponent = Skeleton || DefaultSkeleton;
  const { pathname } = useLocation();

  if (withSession || withNoSession) {
    if (isLoggedIn === null) {
      return <SkeletonComponent />;
    }
  }

  const [, alert] = Object.entries(REDIRECT_ALERTS)
    .find((path) => matchPath(location.pathname, { path: path[0] })) || [];

  if (withSession && isLoggedIn === false) {
    return (
      <Redirect to={{
        pathname: AUTH.SIGNIN,
        state: {
          redirect: location,
          alert,
        },
      }}
      />
    );
  }

  if (isLoggedIn === true) {
    const shouldComplete = !session.user.name || session.user.shouldReset;
    if (shouldComplete && pathname !== AUTH.COMPLETE_PROFILE) {
      const { redirect } = location.state || {};

      return (
        <Redirect
          to={{
            pathname: AUTH.COMPLETE_PROFILE,
            state: {
              redirect: redirect || location,
              alert,
            },
          }}
        />
      );
    }
  }

  if (withNoSession && isLoggedIn === true) {
    const { redirect } = location.state || {};
    const storedRedirect = localStorage.getItem("storedRedirect");
    localStorage.removeItem("storedRedirect");
    
    return <Redirect to={redirect || storedRedirect || APP.OVERVIEW} />;
  }

  return <Route {...rest} />;
}

export default AppRoute;
