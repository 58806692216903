import { forwardRef } from "react";
import { Chip, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { BN } from "@rather-labs/vaults-unifarming-sdk";
import CurrencyAvatar from "components/CurrencyAvatar";
import ChildrenFriendlyButton from "../ChildrenFriendlyButton";
import AssetGroup from "../AssetGroup";

function UnifarmingStrategyItem({ onClick, data, sx, selected, ...rest }, ref) {
  const onClickWrapped = () => {
    onClick(data, ref);
  };

  return (
    <ChildrenFriendlyButton
      color="secondary"
      noPadding
      onClick={onClickWrapped}
      {...rest}
      ref={ref}
    >
      <Paper
        sx={{
          boxShadow: data.rank > 0 ? "0px 0px 15px -5px rgba(53,18,194,0.75)" : undefined,
          background: selected ? "linear-gradient(159.9deg, #1B3266 8.14%, #0B1B3F 88.24%)" : undefined,
          padding: 2,
          width: 280,
          height: "100%",
        }}
      >
        <Stack gap={2}>

          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="caption"
              color={selected ? "white" : "textSecondary"}
            >
              Liquidty Farming
            </Typography>

            <Chip
              color={selected ? "primary" : "default"}
              variant="filled"
              size="small"
              icon={(
                <CurrencyAvatar src={data.network.icon} label={data.network.name} />
              )}
              label={(
                <Typography variant="overline">
                  {data.network.name}
                </Typography>
              )}
            />

          </Stack>

          <Stack>
            <Stack gap={1} direction="row" alignItems="center">
              <AssetGroup assets={data.tokens} />
              <Stack>
                <Typography
                  variant="body2"
                  color={selected ? "primary.light" : "secondary"}
                  fontWeight="bold"
                >
                  {data.tokens.map((token) => token.symbol).join("-").toUpperCase()}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="space-between" flexGrow={1} alignItems="flex-end" mt={1}>
            <Stack textAlign="right">
              <Typography
                variant="overline"
                lineHeight={1}
                fontWeight="bold"
                color={selected ? "white" : "textSecondary"}
              >
                TVL
              </Typography>
              <Typography
                variant="h6"
                color={selected ? "white" : "textSecondary"}
              >
                $
                {BN(data.lastStat?.values.tvl).toFormat(0)}
              </Typography>
            </Stack>

            <Stack textAlign="right">
              <Typography
                variant="overline"
                lineHeight={1}
                fontWeight="bold"
                color={selected ? "white" : "textSecondary"}
              >
                APR
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bolder"
                color="#00AC70"
              >
                {BN(data.lastStat?.values.roiPerYear).times(100).toFixed(1)}
                %
              </Typography>
            </Stack>
          </Stack>
        </Stack>

      </Paper>
    </ChildrenFriendlyButton>
  );
}

const ForwardedUnifarmingStrategyItem = forwardRef(UnifarmingStrategyItem);

ForwardedUnifarmingStrategyItem.Skeleton = forwardRef((props, ref) => (
  <Skeleton
    variant="rectangular"
    width={200}
    height={80}
    {...props}
    ref={ref}
  />
));

export default ForwardedUnifarmingStrategyItem;
