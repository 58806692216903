import { useCallback } from "react";
import useSWR from "swr";
import useHit from "./useHit";

const useResource = (endpoint, { options } = {}) => {
  const hit = useHit();

  const fetcher = useCallback((key) => hit(JSON.parse(key)).then((res) => {
    if (res && res.error) throw new Error(res.error);
    return res;
  }), [hit]);
  
  const key = JSON.stringify(endpoint);
  const { data, error, mutate } = useSWR(key, fetcher, options);
  const refresh = useCallback(() => mutate(data), [data]);

  return {
    data, error, loading: !data && !error, mutate, refresh,
  };
};

export default useResource;
