import { initializeConnector } from "@web3-react/core";
import { WalletConnect } from "@web3-react/walletconnect";
import { CHAINID_TO_NETWORK_NAME, MAP_NETWORK_TO_RPC } from "@rather-labs/vaults-unifarming-sdk/constants";

const RPCS = Object.entries(CHAINID_TO_NETWORK_NAME)
  .reduce((map, [chainId, networkName]) => {
    const validUrl = MAP_NETWORK_TO_RPC[networkName];
    if (validUrl) return { ...map, [chainId]: [validUrl] };
    return map;
  }, {});

export const [walletconnect, hooks] = initializeConnector(
  (actions) => new WalletConnect(actions, {
    rpc: RPCS,
  }),
  Object.keys(RPCS).map((chainId) => Number(chainId)),
);
