import { DialogProvider } from "store/Dialogs";

const withDialogs = (Component) => function (props) {
  return (
    <DialogProvider>
      <Component {...props} />
    </DialogProvider>
  );
};

export default withDialogs;
