import { Web3ReactProvider } from "@web3-react/core";
import { hooks as walletConnectHooks, walletconnect } from "connectors/walletconnect";
import { hooks as metaMaskHooks, metamask } from "connectors/metamask";

const connectors = [
  [metamask, metaMaskHooks],
  [walletconnect, walletConnectHooks],
];

export default (Component) => function (props) {
  return (
    <Web3ReactProvider connectors={connectors}>
      <Component {...props} />
    </Web3ReactProvider>
  );
};
