import React, { StrictMode } from "react";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  ArcElement,
  Legend,
  CategoryScale,
} from "chart.js";

import withSession from "hocs/withSession";
import withDialogs from "hocs/withDialogs";
import withRouter from "hocs/withRouter";
import withToasts from "hocs/withToasts";
import withTheme from "hocs/withTheme";

import ReactDOM from "react-dom";
import App from "App";
import "index.css";
import withI18n from "hocs/withI18n";
import withWeb3 from "hocs/withWeb3";
import withConnectors from "hocs/withConnectors";
import { Buffer } from "buffer";

window.Buffer = window.Buffer || Buffer;

ChartJS.register(ArcElement, Legend, CategoryScale, LineController, LineElement, PointElement, LinearScale, Title);

Sentry.init({
  dsn: "https://16774a3a1a164fdb8378ed6ac53f24f2@o515505.ingest.sentry.io/6292084",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.NODE_ENV,
  ignoreErrors: [
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
    "TypeError: Cancelled",
  ],
});

// order matters!
const hocs = [
  withDialogs,
  withRouter,
  withSession,
  withWeb3,
  withConnectors,
  withI18n,
  withToasts,
  withTheme,
];

const AppContainer = hocs.reduce((app, wrapper) => wrapper(app), App);

ReactDOM.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback="An error has occured">
      <AppContainer />
    </Sentry.ErrorBoundary>
  </StrictMode>,
  document.getElementById("root"),
);

// export default AppContainer;
