import querystring from "querystring";

export const getClientsideHref = () => {
  if (typeof window !== "undefined") {
    return {
      host: window.location.origin,
      query: querystring.parse(window.location.search),
      href: window.location.href,
      hash: window.location.hash.substr(1),
    };
  }
  return {
    host: "",
    query: {

    },
    hash: "",
  };
};

export const uploadFileToS3 = (preSignedUrl, file) => new Promise((res) => {
  const xhr = new XMLHttpRequest();
  xhr.open("PUT", preSignedUrl, true);
  xhr.onload = (data) => {
    if (xhr.status === 200) {
      res(data);
    } else res({ error: data });
  };
  xhr.onerror = (error) => {
    res({ error });
  };
  xhr.send(file); // `file` is a File object here
});

export const copyToClipboard = (val) => {
  const el = document.createElement("textarea");
  el.value = val;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const sleepSeconds = (seconds) => new Promise((res) => (
  setTimeout(res, seconds * 1000)
));

export const chopString = (str, length = 20) => (str.length > length ? `${str.substring(0, length)}...` : str);

export const cancelPropagation = (e) => {
  if (e) {
    e.stopPropagation();
    e.preventDefault();
  }
};

export const parseJsonOrNull = (str) => {
  try {
    return JSON.parse(str);
  } catch (_) {
    return null;
  }
};

export const getFirstName = (fullname) => (fullname ? fullname.split(" ")[0] : "");
export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);
export const mergeStyles = (styles) => styles.reduce((prev, curr) => ({ ...prev, ...curr }), {});
export const isJson = (sample) => {
  try {
    JSON.parse(sample);
    return true;
  } catch (err) {
    return false;
  }
};
export const addDaysToDate = (date, days) => {
  const newDate = new Date(date.valueOf());
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const isScreenMobileSize = () => window.innerWidth < 960;

export const camelToSpace = (key) => (
  key.replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
);
export const inspectRecursively = (context, onObject, onValue, path = []) => {
  Object.entries(context).forEach(([entry, value]) => {
    if (typeof value === "object") {
      let avoidInspection = false;
      if (onObject) {
        avoidInspection = onObject(entry, value, context, path);
      }
      if (!avoidInspection) inspectRecursively(value, onObject, onValue, path.concat(entry));
    } else if (onValue) onValue(entry, value, context, path);
  });
};

export const flattenRecursively = (object, acc) => {
  const result = acc || {};
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value !== "number") {
      flattenRecursively(value, result);
      /* eslint-disable-next-line no-param-reassign */
    } else result[key] = (result[key] || 0) + value;
  });
  return result;
};

export const getLocation = () => new Promise((resolve, reject) => {
  navigator.geolocation.getCurrentPosition(resolve, reject);
});

export const cropAt = (str, l) => (str.length > l ? `${str.substring(0, l)}...` : str);
export const sortGames = (gs) => gs.sort((a) => (a.status.enabled ? -1 : 1));

export const camelCaseToText = (str) => str.replace(/([A-Z]+)/g, " $1").replace(/^ /, "");
export const shuffle = (array) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    /* eslint-disable no-param-reassign */
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
    /* eslint-enable no-param-reassign */
  }

  return array;
};
