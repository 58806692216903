import { AutoGraph, HomeOutlined, Info } from "@mui/icons-material";
import { Avatar, Box, Hidden, Stack, Typography } from "@mui/material";
import ChildrenFriendlyButton from "components/ChildrenFriendlyButton";
import ProfilePopover from "components/ProfilePopover";
import useSession from "hooks/useSession";
import { useLocation } from "react-router-dom";

const links = [
  { label: "Liquidity Farming", link: "/unifarming", Icon: AutoGraph },
  { label: "Market Neutral Vault", link: "/beefy", Icon: AutoGraph },
  { label: "New Position", link: "/new-position", Icon: AutoGraph },
  { label: "Overview", link: "/overview", Icon: HomeOutlined },
  { label: "Information", link: "/info", Icon: Info },
];

function Header() {
  const { pathname } = useLocation();
  const { data: session } = useSession();
  const user = session?.user || {};

  const link = links.find((l) => pathname.includes(l.link)) || {};
  const { Icon, label } = link;

  return (
    <Box
      sx={{
        paddingLeft: 2,
        paddingRight: 2,
        backgroundColor: "background.paper",
      }}
    >
      <Stack
        direction="row"
        sx={{
          height: 80,
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
        >
          <Icon color="primary" fontSize="large" />
          <Typography variant="h5" fontWeight="bold">
            {label}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
        >
          <Hidden smDown>
            <Typography>
              {user.name}
            </Typography>
          </Hidden>
          <ProfilePopover>
            <ChildrenFriendlyButton noPadding color="secondary">
              <Avatar
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </ChildrenFriendlyButton>
          </ProfilePopover>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Header;
