const blockFieldsQuery = `
  fragment blockFields on Block {
    id
    number
    timestamp
  }
`;

const blockQuery = `
  query blockQuery(
    $where: Block_filter
    $orderBy: Block_orderBy! = "timestamp"
    $orderDirection: OrderDirection! = "desc"
  ) {
    blocks(first: 1, where: $where, orderBy: $orderBy, orderDirection: $orderDirection) {
      ...blockFields
    }
  }
  ${blockFieldsQuery}
`;

const blocksQuery = `
  query blocksQuery(
    $first: Int! = 1000
    $skip: Int! = 0
    $where: Block_filter
    $orderBy: Block_orderBy = "timestamp"
    $orderDirection: OrderDirection! = "desc"
  ) {
    blocks(first: $first, skip: $skip, where: $where, orderBy: $orderBy, orderDirection: $orderDirection) {
      ...blockFields
    }
  }
  ${blockFieldsQuery}
`;

const massBlocksQuery = (timestamps) => `
  {
    ${timestamps.map(
    (timestamp) => `
        block${timestamp}: blocks(first: 1, orderBy: timestamp, orderDirection: asc, where: { timestamp_gt: ${timestamp} }) {
            ...blockFields
        },
      `,
  )},
  }
  ${blockFieldsQuery}
  `;

export {
  blockQuery,
  blocksQuery,
  massBlocksQuery,
};
