import { createTheme } from "@mui/material";

export const light = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#ea3b7e",
    },
    secondary: {
      main: "#2e378c",
      dark: "#020825",
    },
    text: {
      primary: "#101b3d",
      secondary: "#676f87",
    },
    background: {
      default: "#f6f8fd",
      paper: "#fff",
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        label: {
          fontWeight: "lighter",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        "&.Mui-disabled": {
          pointerEvents: "auto",
        },
        root: {
          border: "1px solid",
          borderRadius: 8,
        },
      },
    },
  },
  shape: {
    borderRadius: 12,
  },
  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: "capitalize",
      fontWeight: 600,
    },
  },
});

export const dark = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ea3b7e",
    },
    secondary: {
      main: "#2e378c",
    },
    text: {
      primary: "#fff",
      secondary: "#676f87",
    },
    background: {
      default: "#2e378c",
      paper: "#040c22",
    },
  },
});
