import { Paper, Skeleton, Stack, Typography } from "@mui/material";
import routes from "routes";
import { useHistory } from "react-router-dom";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { BN } from "@rather-labs/vaults-unifarming-sdk";
import usePosition from "hooks/usePosition";
import { getTokenPriceAtNet } from "lib/graph";
import ChildrenFriendlyButton from "./ChildrenFriendlyButton";
import AssetGroup from "./AssetGroup";
import ImageChip from "./ImageChip";

function PositionItem({ onClick, data, sx, selected, ...rest }, ref) {
  const history = useHistory();
  const position = usePosition(data.address, data.strategy.network.name.toLowerCase());
  const [deposited, setDeposited] = useState(null);

  const calculateBalanceInUsd = useCallback(async () => {
    const { addressA, addressB, symbolA, decimalsA, decimalsB, symbolB } = position;
  
    const [
      underlyingBalance,
      priceTokenA,
      priceTokenB,
    ] = await Promise.all([
      position.getUnderlyingBalance(),
      getTokenPriceAtNet(data.strategy.network.name.toLowerCase(), { id: addressA.toLowerCase() }),
      getTokenPriceAtNet(data.strategy.network.name.toLowerCase(), { id: addressB.toLowerCase() }),
    ]);

    return underlyingBalance[symbolA].scaleDown(decimalsA).times(priceTokenA)
      .plus(underlyingBalance[symbolB].scaleDown(decimalsB).times(priceTokenB));
  }, [position]);

  useEffect(() => {
    if (position) calculateBalanceInUsd().then(setDeposited);
  }, [position, calculateBalanceInUsd]);

  const navigate = () => {
    history.push(routes.APP.UNIFARMING.replace(":positionId", data.id));
  };

  return (
    <ChildrenFriendlyButton
      color="secondary"
      noPadding
      onClick={navigate}
      {...rest}
      ref={ref}
    >
      <Paper
        sx={{
          boxShadow: data.strategy.rank > 0 ? "0px 0px 15px -5px rgba(53,18,194,0.75)" : undefined,
          background: selected ? "linear-gradient(159.9deg, #1B3266 8.14%, #0B1B3F 88.24%)" : undefined,
          padding: 2,
          width: 280,
          height: "100%",
        }}
      >
        <Stack gap={2}>

          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="caption"
              color={selected ? "white" : "textSecondary"}
            >
              Liquidty Farming
            </Typography>

            <ImageChip src={data.strategy.network.icon} label={data.strategy.network.name} />
          </Stack>

          <Stack>
            <Stack gap={1} direction="row" alignItems="center">
              <AssetGroup assets={data.strategy.tokens} />
              <Stack>
                <Typography
                  variant="body2"
                  color={selected ? "primary.light" : "secondary"}
                  fontWeight="bold"
                >
                  {data.strategy.tokens.map((token) => token.symbol).join("-").toUpperCase()}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="space-between" flexGrow={1} alignItems="flex-end" mt={1}>
            <Stack textAlign="right">
              <Typography
                variant="overline"
                lineHeight={1}
                fontWeight="bold"
                color={selected ? "white" : "textSecondary"}
              >
                DEPOSITED
              </Typography>
              {deposited
              
                ? (
                  <Typography
                    variant="h6"
                    color={selected ? "white" : "textSecondary"}
                  >
                    $
                    {BN(deposited).toFormat(0)}
                  </Typography>
                )
                : <Skeleton variant="rectangular" width={100} height={30} />}
            </Stack>

            <Stack textAlign="right">
              <Typography
                variant="overline"
                lineHeight={1}
                fontWeight="bold"
                color={selected ? "white" : "textSecondary"}
              >
                APR
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bolder"
                color="#00AC70"
              >
                {BN(data.strategy.lastStat.values.roiPerYear).times(100).toFixed(1)}
                %
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      
      </Paper>
    </ChildrenFriendlyButton>
  );
}

export default forwardRef(PositionItem);
