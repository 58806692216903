module.exports = Object.freeze({
  SIGN_OUT: "Cerrar sesión",
  ARE_YOU_SURE_SIGN_OUT: "Estás por cerrar sesión, ¿estás seguro?",
  OVERALL_STATS: "Estadísticas Generales",
  COMING_SOON: "Pronto Disponible",
  POSITIONS: "Posiciones Abiertas",
  NEW_POSITION: "Nueva Posición",
  STRATEGIES: "Estrategias",
  RECOMMENDED_STRATEGIES: "Estrategias Recomendadas",
  ALL_STRATEGIES: "Todas las Estrategias",
});
