import React, { useRef, useState } from "react";
import { BN } from "@rather-labs/vaults-unifarming-sdk";
import { Button, Stack, TextField, Typography } from "@mui/material";
import AlertContainer from "components/AlertContainer";
import { useDialogs } from "store/Dialogs";
import DialogForm from "./DialogForm";
import ConfirmTransactionDialog from "./ConfirmTransactionDialog";

function AddLiquidityDialog({
  instance,
  metrics,
  signer,
  onClose,
  onResult,
  wallet,
  ...rest
}) {
  const [amountA, setAmountA] = useState(BN(0));
  const [amountB, setAmountB] = useState(BN(0));
  const [loading, setLoading] = useState(false);
  const alertContainer = useRef();
  const dialogs = useDialogs();

  const { idleBalance, pairLiquidity } = metrics;
  const { provider, symbolA, decimalsA, decimalsB, symbolB } = instance;

  const idleBalanceA = idleBalance[symbolA].scaleDown(decimalsA);
  const idleBalanceB = idleBalance[symbolB].scaleDown(decimalsB);
  const reserveA = pairLiquidity[symbolA].scaleDown(decimalsA);
  const reserveB = pairLiquidity[symbolB].scaleDown(decimalsB);
  const ratio = reserveA.div(reserveB);

  const assemble = async () => {
    if (amountA.gt(idleBalance[symbolA])) {
      alertContainer.current.setAlert({ severity: "error", text: `Not enough ${symbolA}` });
      return;
    }
    if (amountB.gt(idleBalance[symbolB])) {
      alertContainer.current.setAlert({ severity: "error", text: `Not enough ${symbolB}` });
      return;
    }
    alertContainer.current.clear();

    setLoading(true);

    const tx = await instance.buildAssembleTransaction({
      amountA: amountA.scaleUp(decimalsA),
      amountB: amountB.scaleUp(decimalsB),
    });
    tx.from = wallet;

    try {
      const transaction = await signer.sendTransaction(tx);
      dialogs.create({
        DialogType: ConfirmTransactionDialog,
        txHash: transaction.hash,
        provider,
        onResult,
      });
      onClose();
    } catch (e) {
      console.log(e);
      //
    } finally {
      setLoading(false);
    }
  };

  const setMaxAmount = () => {
    if (idleBalanceA < idleBalanceB.times(ratio)) {
      setAmountA(idleBalanceA);
      setAmountB(idleBalanceA.div(ratio));
    } else {
      setAmountA(idleBalanceB.times(ratio));
      setAmountB(idleBalanceB);
    }
  };

  return (
    <DialogForm
      cross
      onClose={onClose}
      title={`Deploy ${symbolA}-${symbolB} to your position`}
      actions={[
        <Button
          onClick={assemble}
          variant="contained"
          disabled={loading}
          color="secondary"
        >
          Deposit
        </Button>,
      ]}
      {...rest}
    >
      <Stack spacing={2} mt={1}>
        <Stack>
          <TextField
            type="number"
            value={amountA.toString()}
            label={symbolA}
            onChange={({ target: { value } }) => {
              const fixedValue = Number(value) > 0 ? value : 0;
              setAmountA(BN(fixedValue));
              setAmountB(BN(fixedValue).div(ratio));
            }}
            InputProps={{
              endAdornment: (
                <Button
                  size="small"
                  onClick={setMaxAmount}
                >
                  MAX
                </Button>
              ),
            }}
          />
          <Typography variant="caption" fontWeight="bold">
            {`Balance: ${idleBalanceA} ${symbolA}`}
          </Typography>
        </Stack>
        <Stack>
          <TextField
            type="number"
            label={symbolB}
            value={amountB.toString()}
            onChange={({ target: { value } }) => {
              const fixedValue = Number(value) > 0 ? value : 0;
              setAmountB(BN(fixedValue));
              setAmountA(BN(fixedValue).times(ratio));
            }}
            InputProps={{
              endAdornment: (
                <Button
                  size="small"
                  onClick={setMaxAmount}
                >
                  MAX
                </Button>
              ),
            }}
          />
          <Typography variant="caption" fontWeight="bold">
            {`Balance: ${idleBalanceB} ${symbolB}`}
          </Typography>
        </Stack>
        <AlertContainer ref={alertContainer} />
      </Stack>
    </DialogForm>
  );
}

export default AddLiquidityDialog;
