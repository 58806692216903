import { GRAPH_HOST } from "../constants";
import {
  dayDatasQuery,
  ethPriceQuery,
  factoryQuery,
  liquidityPositionsQuery,
  pairDayDatasQuery,
  pairsQuery,
  tokenDayDatasQuery,
  tokenPairsQuery,
  tokenPriceQuery,
  tokensQuery,
  tokenSubsetQuery,
  transactionsQuery,
} from "../queries";
import pager from "./pager";

const EXCHANGE = {
  ethereum: "sushiswap/exchange",
  xdai: "sushiswap/xdai-exchange",
  polygon: "sushiswap/matic-exchange",
  fantom: "sushiswap/fantom-exchange",
  bsc: "sushiswap/bsc-exchange",
  harmony: "sushiswap/harmony-exchange",
  avalanche: "sushiswap/avalanche-exchange",
  celo: "jiro-ono/sushitestsubgraph",
  arbitrum: "sushiswap/arbitrum-exchange",
  moonriver: "sushiswap/moonriver-exchange",
  okex: "okex-exchange/oec",
  heco: "heco-exchange/heco",
  fuse: "sushiswap/fuse-exchange",
  moonbeam: "sushiswap/moonbeam-exchange",
};

// @ts-ignore TYPE NEEDS FIXING
const exchange = async (network, query, variables = {}) => pager(`${GRAPH_HOST[network]}/subgraphs/name/${EXCHANGE[network]}`, query, variables);

const getPairs = async (network, variables = undefined, query = pairsQuery) => {
  const { pairs } = await exchange(network, query, variables);
  return pairs;
};

// @ts-ignore TYPE NEEDS FIXING
const getPairDayData = async (network, variables) => {
  // console.log('getTokens')
  const { pairDayDatas } = await exchange(network, pairDayDatasQuery, variables);
  return pairDayDatas;
};

// @ts-ignore TYPE NEEDS FIXING
const getTokenSubset = async (network, variables) => {
  // console.log('getTokenSubset')
  const { tokens } = await exchange(network, tokenSubsetQuery, variables);
  return tokens;
};

// @ts-ignore TYPE NEEDS FIXING
const getTokens = async (network, variables) => {
  // console.log('getTokens')
  const { tokens } = await exchange(network, tokensQuery, variables);
  return tokens;
};

// @ts-ignore TYPE NEEDS FIXING
const getToken = async (network, query, variables) => {
  // console.log('getTokens')
  const { token } = await exchange(network, query, variables);
  return token;
};

// @ts-ignore TYPE NEEDS FIXING
const getTokenDayData = async (network, variables) => {
  // console.log('getTokens')
  const { tokenDayDatas } = await exchange(network, tokenDayDatasQuery, variables);
  return tokenDayDatas;
};

// @ts-ignore TYPE NEEDS FIXING
const getTokenPrices = async (network, variables) => {
  // console.log('getTokenPrice')
  const { tokens } = await exchange(network, tokensQuery, variables);
  // @ts-ignore TYPE NEEDS FIXING
  return tokens.map((token) => token?.derivedETH);
};

const getBundle = async (
  network = "ethereum",
  query = ethPriceQuery,
  variables = {
    id: 1,
  },
) => exchange(network, query, variables);

const getNativePrice = async (network, variables = undefined) => {
  // console.log('getEthPrice')
  const data = await getBundle(network, undefined, variables);
  return data?.bundles[0]?.ethPrice;
};

// @ts-ignore TYPE NEEDS FIXING
const getTokenPrice = async (network, query, variables) => {
  // console.log('getTokenPrice')
  const nativePrice = await getNativePrice(network);

  const { token } = await exchange(network, query, variables);
  return token ? token.derivedETH * nativePrice : -1;
};

const getTokenPriceAtNet = async (network, variables) => getTokenPrice(network, tokenPriceQuery, variables);

const getEthPrice = async (variables = undefined) => getNativePrice("ethereum", variables);

const getGlimmerPrice = async (variables = {}) => getTokenPrice("moonbeam", tokenPriceQuery, {
  id: "0xacc15dc74880c9944775448304b263d191c6077f",
  ...variables,
});

const getYggPrice = async (variables = {}) => getTokenPrice("ethereum", tokenPriceQuery, {
  id: "0x25f8087ead173b73d6e8b84329989a8eea16cf73",
  ...variables,
});

const getRulerPrice = async (variables = {}) => getTokenPrice("ethereum", tokenPriceQuery, {
  id: "0x2aeccb42482cc64e087b6d2e5da39f5a7a7001f8",
  ...variables,
});

const getTruPrice = async (variables = {}) => getTokenPrice("ethereum", tokenPriceQuery, {
  id: "0x4c19596f5aaff459fa38b0f7ed92f11ae6543784",
  ...variables,
});

const getCvxPrice = async (variables = {}) => getTokenPrice("ethereum", tokenPriceQuery, {
  id: "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b",
  ...variables,
});

const getMaticPrice = async (variables = {}) => getTokenPrice("polygon", tokenPriceQuery, {
  id: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
  ...variables,
});
const getAlcxPrice = async (variables = {}) => getTokenPrice("ethereum", tokenPriceQuery, {
  id: "0xdbdb4d16eda451d0503b854cf79d55697f90c8df",
  ...variables,
});
const getPicklePrice = async (variables = {}) => getTokenPrice("ethereum", tokenPriceQuery, {
  id: "0x429881672b9ae42b8eba0e26cd9c73711b891ca5",
  ...variables,
});

const getMphPrice = async (variables = {}) => getTokenPrice("ethereum", tokenPriceQuery, {
  id: "0x8888801af4d980682e47f1a9036e589479e835c5",
  ...variables,
});

const getSushiPrice = async (variables = {}) => getTokenPrice("ethereum", tokenPriceQuery, {
  id: "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
  ...variables,
});
const getGnoPrice = async () => getTokenPrice("xdai", tokenPriceQuery, {
  id: "0x9c58bacc331c9aa871afd802db6379a98e80cedb",
});

const getOnePrice = async (variables = undefined) => getNativePrice("harmony", variables);

const getAvaxPrice = async (variables = undefined) => getNativePrice("avalanche", variables);

const getCeloPrice = async () => getTokenPrice("celo", tokenPriceQuery, {
  id: "0x471ece3750da237f93b8e339c536989b8978a438",
});

const getFantomPrice = async () => getTokenPrice("fantom", tokenPriceQuery, {
  id: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
});

// @ts-ignore TYPE NEEDS FIXING
const getOhmPrice = async (network) => {
  if (network === "arbitrum") {
    return getTokenPrice("arbitrum", tokenPriceQuery, {
      id: "0x8d9ba570d6cb60c7e3e0f31343efe75ab8e65fb1",
    });
  }
  return getTokenPrice("polygon", tokenPriceQuery, {
    id: "0xd8ca34fd379d9ca3c6ee3b3905678320f5b45195",
  });
};

const getMagicPrice = async () => getTokenPrice("arbitrum", tokenPriceQuery, {
  id: "0x539bde0d7dbd336b79148aa742883198bbf60342",
});

const getMovrPrice = async () => getTokenPrice("moonriver", tokenPriceQuery, {
  id: "0xf50225a84382c74cbdea10b0c176f71fc3de0c4d",
});

const getSpellPrice = async () => getTokenPrice("ethereum", tokenPriceQuery, {
  id: "0x090185f2135308bad17527004364ebcc2d37e5f6",
});

const getFusePrice = async () => getTokenPrice("fuse", tokenPriceQuery, {
  id: "0x0be9e53fd7edac9f859882afdda116645287c629",
});

// @ts-ignore TYPE NEEDS FIXING
const getLiquidityPositions = async (network, variables) => {
  const { liquidityPositions } = await exchange(network, liquidityPositionsQuery, variables);
  return liquidityPositions;
};

const getDayData = async (network, variables = undefined) => {
  const { dayDatas } = await exchange(network, dayDatasQuery, variables);
  return dayDatas;
};

const getFactory = async (network, variables = undefined) => {
  const { factories } = await exchange(network, factoryQuery, variables);
  return factories[0];
};

const getTransactions = async (network, variables = undefined) => {
  const { swaps } = await exchange(network, transactionsQuery, variables);
  return swaps;
};

const getTokenPairs = async (network, variables = undefined) => {
  const { pairs0, pairs1 } = await exchange(network, tokenPairsQuery, variables);
  return pairs0 || pairs1 ? [...(pairs0 || []), ...(pairs1 || [])] : undefined;
};

export {
  exchange,
  getPairs,
  getPairDayData,
  getTokenSubset,
  getTokens,
  getToken,
  getTokenDayData,
  getTokenPrices,
  getTokenPrice,
  getNativePrice,
  getEthPrice,
  getGlimmerPrice,
  getYggPrice,
  getRulerPrice,
  getTruPrice,
  getCvxPrice,
  getMaticPrice,
  getAlcxPrice,
  getPicklePrice,
  getMphPrice,
  getSushiPrice,
  getGnoPrice,
  getOnePrice,
  getAvaxPrice,
  getCeloPrice,
  getFantomPrice,
  getOhmPrice,
  getMagicPrice,
  getMovrPrice,
  getSpellPrice,
  getFusePrice,
  getBundle,
  getLiquidityPositions,
  getDayData,
  getFactory,
  getTransactions,
  getTokenPairs,
  getTokenPriceAtNet,
};
