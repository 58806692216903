import APIEndpoints from "APIEndpoints";
import useHit from "hooks/useHit";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPositionAt } from "@rather-labs/vaults-beefy-sdk";
import { MAP_NETWORK_TO_RPC } from "@rather-labs/vaults-beefy-sdk/constants";
import { ethers } from "ethers";
import { Divider, Grid, Link, Paper, Stack, Typography } from "@mui/material";
import SplashLoading from "components/SplashLoading";
import SectionTitle from "components/SectionTitle";
import FadeInDelay from "components/FadeInDelay";
import { chopString } from "utils";
import BeefyPositionActions from "components/Unifarming/BeefyPositionActions";
import { AutoAwesome, AutoGraph, Summarize } from "@mui/icons-material";
import StatPaper from "components/Unifarming/StatPaper";
import AssetGroup from "components/AssetGroup";
import ImageChip from "components/ImageChip";
import useBeefyrowingStats from "hooks/useBeefyrowingStats";

const mapNetworkToExplorer = {
  ethereum: "https://etherscan.io",
  polygon: "https://polygonscan.com",
  celo: "https://explorer.celo.org",
  fuse: "https://explorer.fuse.io",
  bsc: "https://bscscan.com",
  fantom: "https://ftmscan.com/",
};

function Beefyrowing() {
  const { positionId } = useParams();
  const hit = useHit();
  const [position, setPosition] = useState(null);
  const [instance, setInstance] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [beefyStrat, setBeefyStrat] = useState(null);
  const { roi: beefyrowingRoi } = useBeefyrowingStats(beefyStrat);

  useEffect(() => {
    if (position) {
      setBeefyStrat(position.strategy);
    }
  }, [position]);

  const fetchMetrics = useCallback(async () => {
    const [
      marketValue,
      poolOwnership,
      costOfInvestment,
      idleBalance,
    ] = await Promise.all([
      instance.getMarketValue(),
      instance.getOwnership(),
      instance.getCostOfInvestment(),
      instance.getIdleBalance(),
    ]);

    return ({
      marketValue: marketValue.toNumber(),
      poolOwnership: poolOwnership.times(100).toNumber(),
      costOfInvestment: costOfInvestment.toNumber(),
      idleBalance,
    });
  }, [instance]);

  const computeROI = () => {
    const mktValue = metrics.marketValue;
    const cost = metrics.costOfInvestment;
    if (cost === 0) {
      return 0;
    }

    return mktValue / cost - 1;
  };

  useEffect(() => {
    hit(APIEndpoints.POSITIONS.GET_BY_ID(positionId))
      .then(({ error, ...data }) => !error && setPosition(data));
  }, [positionId, hit]);

  useEffect(() => {
    if (position) {
      const jsonRpcUrl = MAP_NETWORK_TO_RPC[position.network];
      const provider = new ethers.providers.JsonRpcProvider(jsonRpcUrl);

      getPositionAt({
        positionAddress: position.address,
        strategyId: position.strategy.id,
        provider,
      }).then(setInstance);
    }
  }, [position]);

  const updateMetrics = useCallback(() => {
    if (instance) fetchMetrics().then(setMetrics);
  }, [instance, fetchMetrics]);

  useEffect(() => {
    updateMetrics();
  }, [instance, fetchMetrics, updateMetrics]);

  const handleResult = (result) => {
    if (result) updateMetrics();
  };

  if (!metrics) return <SplashLoading label="We are fetching information" />;

  const positionExplorerLink = `${mapNetworkToExplorer[position.network]}/address/${position.address}`;

  return (
    <Stack gap={2}>

      <FadeInDelay delay={250}>
        <Stack gap={2}>
          <Stack direction="row" justifyContent="space-between" gap={1}>
            <SectionTitle variant="h6" Icon={Summarize}>
              {position.strategy.name}
            </SectionTitle>
            <Stack gap={1} direction="row">
              <StatPaper
                label="ROI"
                value={(computeROI() * 100).toFixed(1).concat("%")}
              />
              <StatPaper
                label="APR"
                value={(beefyrowingRoi * 100).toFixed(1).concat("%")}
              />
              <StatPaper
                label="Pool Ownership"
                value={`${(metrics.poolOwnership < 0.1) ? "<0.1" : metrics.poolOwnership.toFixed(1)}%`}
              />
            </Stack>
          </Stack>
        </Stack>
      </FadeInDelay>

      <Grid container gap={2}>
        <Grid item xs={12} sm={12} md={3}>
          <FadeInDelay delay={350}>
            <Paper sx={{ padding: 2 }}>
              <Stack gap={1.5}>
                <Stack gap={1}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <AssetGroup assets={position.strategy.tokens} />
                    <Typography fontWeight="bold" variant="h6">
                      {position.strategy.tokens.map((token) => token.symbol).join("-")}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography color="textSecondary" variant="body2">
                      Blockchain
                    </Typography>
                    <ImageChip src={position.strategy.network.icon} label={position.strategy.network.name} />
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography color="textSecondary" variant="body2">
                      Investment Protocol
                    </Typography>
                    <ImageChip src={position.strategy.protocol.icon} label={position.strategy.protocol.name} />
                  </Stack>
                </Stack>

                <Divider />

                <Stack gap={1}>
                  <Typography fontWeight="bold" variant="h6">
                    Details
                  </Typography>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Market Value
                    </Typography>
                    <Typography fontWeight="bold" variant="body1">
                      $
                      {metrics.marketValue.toFixed(2)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Cost of Investment
                    </Typography>
                    <Typography fontWeight="bold" variant="body1">
                      $
                      {metrics.costOfInvestment.toFixed(2)}
                    </Typography>
                  </Stack>
                </Stack>

                <Divider />

                <Stack gap={1}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight="bold" variant="h6">
                      PnL
                    </Typography>
                    <Typography fontWeight="bold" variant="body1">
                      $
                      {(metrics.marketValue - metrics.costOfInvestment).toFixed(2)}
                    </Typography>
                  </Stack>
                </Stack>

                <Divider />

                <Stack gap={1}>
                  <Typography fontWeight="bold" variant="h6">
                    Addresses
                  </Typography>

                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Position
                    </Typography>
                    <Typography variant="body2">
                      <Link target="_blank" color="secondary" href={positionExplorerLink}>
                        {chopString(position.address)}
                      </Link>
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Investment Vault
                    </Typography>
                    <Typography variant="body2">
                      <Link target="_blank" color="secondary" href={positionExplorerLink}>
                        {chopString(position.strategy.metadata.beefyData.vault)}
                      </Link>
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Supply Contract
                    </Typography>
                    <Typography variant="body2">
                      <Link target="_blank" color="secondary" href={positionExplorerLink}>
                        {chopString(position.strategy.metadata.ironbankData.supplyPairTokens[0][1])}
                      </Link>
                    </Typography>
                  </Stack>

                </Stack>

              </Stack>
            </Paper>
          </FadeInDelay>

        </Grid>
        <Grid item md>
          <FadeInDelay delay={400}>
            <Paper sx={{ padding: 2, height: "100%" }}>
              <Stack gap={2}>
                <SectionTitle Icon={AutoGraph}>
                  Historical Data
                </SectionTitle>
                <img
                  src="/backtesting-placeholder.png"
                  style={{
                    filter: "blur(5px)",
                    maxHeight: 300,
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </Stack>
            </Paper>
          </FadeInDelay>
        </Grid>
      </Grid>

      <Grid container gap={2} mt={2}>
        <Grid item xs={12} sm={3}>
          <Stack gap={2} height="100%">
            <FadeInDelay delay={500}>
              <SectionTitle Icon={AutoAwesome}>
                Invest
              </SectionTitle>
            </FadeInDelay>
            <FadeInDelay delay={550}>
              <BeefyPositionActions
                instance={instance}
                position={position}
                metrics={metrics}
                handleResult={handleResult}
              />
            </FadeInDelay>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default Beefyrowing;
