import { Paper, Skeleton, Stack, Typography, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { toBN } from "@rather-labs/vaults-unifarming-sdk/lib/utils";
import { ArrowUpward, ArrowDownward, CompareArrows } from "@mui/icons-material";
import ImageChip from "components/ImageChip";

const timestampToDate = (timestamp) => {
  const d = new Date(timestamp.toNumber() * 1000);
  let s = d.toUTCString();
  s = `${s.substring(0, s.indexOf("GMT"))}UTC`;
  return s;
};

const eventTypesIcons = {
  "Liquidity Added": {
    Icon: ArrowDownward,
    text: "Added",
    color: { sx: "green" },
  },
  "Liquidity Removed": {
    Icon: ArrowUpward,
    text: "Removed",
    color: { mui: "primary" },
  },
  Dividend: {
    Icon: CompareArrows,
    text: "Dividend",
    color: { mui: "secondary" },
  },
};

function EventTypeItem({ type }) {
  const { Icon, text, color } = eventTypesIcons[type];

  return (
    <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
      <Icon
        color={color.mui ? color.mui : undefined}
        sx={color.sx ? { color: color.sx } : undefined}
        fontSize="large"
      />
      <Typography fontWeight="bold">{text}</Typography>
    </Stack>
  );
}

function EventAssetsItem({ event: e, instance, position }) {
  const { amountA, amountB, amountUSD } = e;
  const { decimalsA, decimalsB, symbolA, symbolB } = instance;

  return (
    <Stack>
      <Typography fontWeight="bold">
        {`$${amountUSD.toFixed(1)}`}
      </Typography>
      <Stack direction="row" gap={0.5} justifyContent="flex-end">
        {position.strategy.tokens.map((token) => {
          let amount;
          let decimals;
          if (token.symbol === symbolA) {
            amount = amountA;
            decimals = decimalsA;
          } else if (token.symbol === symbolB) {
            amount = amountB;
            decimals = decimalsB;
          }
          return (
            <ImageChip
              src={token.icon}
              label={toBN(amount).scaleDown(decimals).toFixed(1)}
              hoverText={token.symbol}
            />
          );
        })}
      </Stack>

      {/* <Typography fontSize="small">
        {`${toBN(amountA).scaleDown(decimalsA).toFixed(1)}${symbolA} - ${toBN(amountB).scaleDown(decimalsB).toFixed(1)}${symbolB}`}
      </Typography> */}
    </Stack>
  );
}

function PositionEvent({ event: e, instance, position }) {
  return (
    <TableRow>
      <TableCell align="left">
        <EventTypeItem type={e.eventType} />
      </TableCell>
      <TableCell align="left">
        <Typography>
          {timestampToDate(e.timestamp)}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <EventAssetsItem event={e} instance={instance} position={position} />
      </TableCell>
    </TableRow>
  );
}

function PositionActivity({ instance, events, position, ...props }, ref) {
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    if (events) {
      setFilteredEvents(events.filter((e) => ["Liquidity Added", "Liquidity Removed", "Dividend"].includes(e.eventType)).reverse());
    }
  }, [events]);

  useEffect(() => {
    if (position) console.log(position.strategy);
  }, [position]);

  if (!filteredEvents.length) {
    return (
      <Stack spacing={2} ref={ref} {...props}>
        {Array(3).fill().map(() => (
          <Skeleton
            variant="rectangular"
            height={30}
            width="100%"
          />
        ))}
      </Stack>
    );
  }

  return (
    <Paper sx={{ padding: 2, height: "100%", maxHeight: 400, overflowY: "auto" }} ref={ref} {...props}>
      {/* <TableContainer
      component={Paper}
      ref={ref}
      {...props}
    // sx={{ minWidth: 650 }}
    > */}
      <Table>
        <TableBody>
          {filteredEvents.map((e, i) => (
            <PositionEvent event={e} key={i} instance={instance} position={position} />
          ))}
        </TableBody>
      </Table>
      {/* </TableContainer> */}
    </Paper>
  );
}

export default forwardRef(PositionActivity);
