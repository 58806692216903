import { Fade } from "@mui/material";

function FadeInDelay({ delay, children, ...rest }) {
  return (
    <Fade style={{ transitionDelay: delay }} in {...rest}>
      {children}
    </Fade>
  );
}

export default FadeInDelay;
