import Popoverize from "components/Popoverize";
import { useDialogs } from "store/Dialogs";
import { MenuItem, ListItem } from "@mui/material";
import useTranslation from "hooks/useTranslation";
import useSession from "hooks/useSession";

function ProfilePopover({ children, ...rest }) {
  const dialogs = useDialogs();
  const session = useSession();
  const { t } = useTranslation();
  
  const signOut = async () => {
    const shouldCloseSession = await dialogs.confirm({
      title: t("ARE_YOU_SURE_SIGN_OUT"),
      maxWidth: "xs",
      fullScreen: false,
    });
    if (shouldCloseSession) {
      localStorage.removeItem("session");
      session.refresh();
    }
  };

  return (
    <Popoverize
      {...rest}
      options={(
        <>
          <MenuItem id="mibSignOut" onClick={signOut}>
            {t("SIGN_OUT")}
          </MenuItem>
          <ListItem
            dense
            style={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          />
        </>
      )}
    >
      {children}
    </Popoverize>
  );
}

export default ProfilePopover;
