import APIEndpoints from "APIEndpoints";
import useHit from "hooks/useHit";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPositionAt, PnL } from "@rather-labs/vaults-unifarming-sdk";
import { MAP_NETWORK_TO_RPC } from "@rather-labs/vaults-unifarming-sdk/constants";
import { ethers } from "ethers";
import { Divider, Grid, Link, Paper, Stack, Typography } from "@mui/material";
import SplashLoading from "components/SplashLoading";
import SectionTitle from "components/SectionTitle";
import FadeInDelay from "components/FadeInDelay";
import { chopString } from "utils";
import PositionActions from "components/Unifarming/PositionActions";
import PositionActivity from "components/Unifarming/PositionActivity";
import { AutoAwesome, AutoGraph, EventNote, Summarize } from "@mui/icons-material";
import StatPaper from "components/Unifarming/StatPaper";
import AssetGroup from "components/AssetGroup";
import ImageChip from "components/ImageChip";

const mapNetworkToExplorer = {
  ethereum: "https://etherscan.io",
  polygon: "https://polygonscan.com",
  celo: "https://explorer.celo.org",
  fuse: "https://explorer.fuse.io",
  bsc: "https://bscscan.com",
};

const METRICS_DECIMALS = 2;

function Unifarming() {
  const { positionId } = useParams();
  const hit = useHit();
  const [position, setPosition] = useState(null);
  const [instance, setInstance] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [positionPnL, setPositionPnL] = useState(null);

  const fetchMetrics = useCallback(async () => {
    const [
      lptBalance,
      lptTotalSupply,
      divergence,
      accruedRewards,
      accruedRewardsAsUSD,
      initialSupply,
      underlyingBalance,
      idleBalance,
      pairLiquidity,
    ] = await Promise.all([
      instance.getLptBalance(),
      instance.getLptTotalSupply(),
      instance.getDivergence(),
      instance.getAccruedRewards(false),
      instance.getAccruedRewardsAsUSD(false),
      instance.getInitialSupply(),
      instance.getUnderlyingBalance(),
      instance.getIdleBalance(),
      instance.getPairLiquidity(),
    ]);
    return ({
      lptBalance,
      lptTotalSupply,
      divergence,
      accruedRewards,
      accruedRewardsAsUSD,
      initialSupply,
      underlyingBalance,
      idleBalance,
      pairLiquidity,
    });
  }, [instance]);

  const computePnL = useCallback(async () => {
    const newPnL = await PnL(instance);
    const toBlockNumber = await instance.provider.getBlockNumber();
    await newPnL.compute(
      {
        fromBlock: 12234808 - 200,
        toBlock: toBlockNumber,
        // freq: "hourly",
      },
    );
    setPositionPnL(newPnL);
  }, [instance]);

  useEffect(() => {
    hit(APIEndpoints.POSITIONS.GET_BY_ID(positionId))
      .then(({ error, ...data }) => !error && setPosition(data));
  }, [positionId, hit]);

  useEffect(() => {
    if (position) {
      const jsonRpcUrl = MAP_NETWORK_TO_RPC[position.network];
      const provider = new ethers.providers.JsonRpcProvider(jsonRpcUrl);

      getPositionAt({
        positionAddress: position.address,
        protocol: position.protocol,
        farm: position.farm,
        provider,
      }).then(setInstance);
    }
  }, [position]);

  const updateMetrics = useCallback(() => {
    if (instance) fetchMetrics().then(setMetrics);
  }, [instance, fetchMetrics]);

  useEffect(() => {
    if (instance) {
      computePnL();
    }
  }, [instance, computePnL]);

  useEffect(() => {
    updateMetrics();
  }, [instance, fetchMetrics, updateMetrics]);

  const handleResult = (result) => {
    if (result) updateMetrics();
  };

  if (!metrics) return <SplashLoading label="We are fetching information" />;

  const positionExplorerLink = `${mapNetworkToExplorer[position.network]}/address/${position.address}`;

  const { lptBalance, lptTotalSupply } = metrics;
  const ownership = lptBalance.div(lptTotalSupply).times(100);

  return (
    <Stack gap={2}>

      <FadeInDelay delay={250}>
        <Stack gap={2}>
          <Stack direction="row" justifyContent="space-between" gap={1}>
            <SectionTitle variant="h6" Icon={Summarize}>
              {position.strategy.name}
            </SectionTitle>
            <Stack gap={1} direction="row">
              <StatPaper
                label="ROI"
                value={positionPnL?.getLastROI().times(100).toFixed(1).concat("%")}
              />
              <StatPaper
                label="APR"
                value={(position.strategy.lastStat.values.roiPerYear * 100).toFixed(1).concat("%")}
              />
              <StatPaper
                label="Pool Ownership"
                value={`${ownership.lt(0.1) ? "<0.1" : ownership.toFixed(1)}%`}
              />
            </Stack>
          </Stack>
        </Stack>
      </FadeInDelay>

      <Grid container gap={2}>
        <Grid item xs={12} sm={12} md={3}>
          <FadeInDelay delay={350}>
            <Paper sx={{ padding: 2 }}>
              <Stack gap={1.5}>
                <Stack gap={1}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <AssetGroup assets={position.strategy.tokens} />
                    <Typography fontWeight="bold" variant="h6">
                      {position.strategy.tokens.map((token) => token.symbol).join("-")}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography color="textSecondary" variant="body2">
                      Blockchain
                    </Typography>
                    <ImageChip src={position.strategy.network.icon} label={position.strategy.network.name} />
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography color="textSecondary" variant="body2">
                      Protocol
                    </Typography>
                    <ImageChip src={position.strategy.protocol.icon} label={position.strategy.protocol.name} />
                  </Stack>
                </Stack>

                <Divider />

                <Stack gap={1}>
                  <Typography fontWeight="bold" variant="h6">
                    Details
                  </Typography>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Market Value
                    </Typography>
                    <Typography fontWeight="bold" variant="body1">
                      $
                      {positionPnL?.getLastMarketValue().toFixed(METRICS_DECIMALS)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Cost of Investment
                    </Typography>
                    <Typography fontWeight="bold" variant="body1">
                      $
                      {positionPnL?.getLastCostOfInvestment().toFixed(METRICS_DECIMALS)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Upcoming Earnings
                    </Typography>
                    <Stack direction="row" gap={1}>
                      {position.strategy.rewardTokens.map((token) => (
                        <ImageChip
                          src={token.icon}
                          label={metrics.accruedRewards[token.symbol].scaleDown(token.decimals).toFixed(2)}
                          hoverText={token.symbol}
                        />
                      ))}
                    </Stack>
                  </Stack>
                </Stack>

                <Divider />

                <Stack gap={1}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight="bold" variant="h6">
                      PnL
                    </Typography>
                    <Typography fontWeight="bold" variant="body2">
                      $
                      {positionPnL?.getLastPnL().toFixed(METRICS_DECIMALS)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Realized
                    </Typography>
                    <Typography fontWeight="bold" variant="body2">
                      $
                      {positionPnL?.getLastRealizedPnL().toFixed(METRICS_DECIMALS)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Unrealized
                    </Typography>
                    <Typography fontWeight="bold" variant="body2">
                      $
                      {positionPnL?.getLastUnrealizedPnL().toFixed(METRICS_DECIMALS)}
                    </Typography>
                  </Stack>
                </Stack>

                <Divider />

                <Stack gap={1}>
                  <Typography fontWeight="bold" variant="h6">
                    Addresses
                  </Typography>

                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Position
                    </Typography>
                    <Typography variant="body2">
                      <Link target="_blank" color="secondary" href={positionExplorerLink}>
                        {chopString(position.address)}
                      </Link>
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Pair
                    </Typography>
                    <Typography variant="body2">
                      <Link target="_blank" color="secondary" href={positionExplorerLink}>
                        {chopString(position.strategy.metadata.pair)}
                      </Link>
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Farm
                    </Typography>
                    <Typography variant="body2">
                      <Link target="_blank" color="secondary" href={positionExplorerLink}>
                        {chopString(position.strategy.metadata.farmAddress)}
                      </Link>
                    </Typography>
                  </Stack>

                </Stack>

              </Stack>
            </Paper>
          </FadeInDelay>

        </Grid>
        <Grid item md>
          <FadeInDelay delay={400}>
            <Paper sx={{ padding: 2, height: "100%" }}>
              <Stack gap={2}>
                <SectionTitle Icon={AutoGraph}>
                  Historical Data
                </SectionTitle>
                <img
                  src="/backtesting-placeholder.png"
                  style={{
                    filter: "blur(5px)",
                    maxHeight: 300,
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </Stack>
            </Paper>
          </FadeInDelay>
        </Grid>
      </Grid>

      <Grid container gap={2} mt={2}>
        <Grid item xs={12} sm={3}>
          <Stack gap={2} height="100%">
            <FadeInDelay delay={500}>
              <SectionTitle Icon={AutoAwesome}>
                Invest
              </SectionTitle>
            </FadeInDelay>
            <FadeInDelay delay={550}>
              <PositionActions
                instance={instance}
                position={position}
                metrics={metrics}
                handleResult={handleResult}
              />
            </FadeInDelay>
          </Stack>
        </Grid>
        <Grid item xs={12} md={8} lg={5}>
          <Stack gap={2}>
            <FadeInDelay delay={650}>
              <SectionTitle Icon={EventNote}>
                Activity
              </SectionTitle>
            </FadeInDelay>
            <FadeInDelay delay={700}>
              <PositionActivity instance={instance} events={positionPnL?.event_t} position={position} />
            </FadeInDelay>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default Unifarming;
