import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AppRoute from "components/AppRoute";

import CompleteProfile from "screens/Auth/CompleteProfile";
import SignIn from "screens/Auth/SignIn";

import AppLayout from "layouts/AppLayout";

import ROUTES from "routes";
import Overview from "screens/App/Overview";
import Unifarming from "screens/App/Unifarming";
import Beefyrowing from "screens/App/Beefyrowing";
import NewPosition from "screens/App/NewPosition";

function RedirectToDefault() {
  return (
    <Redirect to={ROUTES.APP.OVERVIEW} />
  );
}

function App() {
  return (
    <Switch>
      <Route path={Object.values(ROUTES.AUTH)}>
        <Switch>
          <AppRoute withNoSession exact path={ROUTES.AUTH.SIGNIN} component={SignIn} />
          <AppRoute withSession exact path={ROUTES.AUTH.COMPLETE_PROFILE} component={CompleteProfile} />
        </Switch>
      </Route>

      <Route path={Object.values(ROUTES.APP)}>
        <AppLayout>
          <Switch>
            <AppRoute exact withSession path={ROUTES.APP.UNIFARMING} component={Unifarming} />
            <AppRoute exact withSession path={ROUTES.APP.BEEFYROWING} component={Beefyrowing} />
            <AppRoute exact withSession path={ROUTES.APP.OVERVIEW} component={Overview} />
            <AppRoute exact withSession path={ROUTES.APP.NEW_POSITION} component={NewPosition} />
          </Switch>
        </AppLayout>
      </Route>

      <Route component={RedirectToDefault} />
    </Switch>
  );
}

export default App;
