import { Typography } from "@mui/material";

function FormikResponseError({ formik }) {
  if (formik.errors.response) {
    return (
      <Typography variant="caption" color="error" display="block">
        {formik.errors.response}
      </Typography>
    );
  }
  return null;
}

export default FormikResponseError;
