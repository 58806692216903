import { hooks as metamaskHooks } from "connectors/metamask";
import { hooks as walletconnectHooks } from "connectors/walletconnect";
import { createContext, useMemo } from "react";

export const Web3Context = createContext({
  chainId: undefined,
});

const {
  useChainId: useMetamaskChainId,
  useAccounts: useMetamaskAccounts,
  useError: useMetamaskError,
  useIsActivating: useMetamaskIsActivating,
  useIsActive: useMetamaskIsActive,
  useProvider: useMetamaskProvider,
} = metamaskHooks;

const {
  useChainId: useWalletconnectChainId,
  useAccounts: useWalletconnectAccounts,
  useError: useWalletconnectError,
  useIsActivating: useWalletconnectIsActivating,
  useIsActive: useWalletconnectIsActive,
  useProvider: useWalletconnectProvider,
} = walletconnectHooks;

export function Web3Provider({ children }) {
  const metamaskChainId = useMetamaskChainId();
  const walletconnectChainId = useWalletconnectChainId();
  
  const metamaskAccounts = useMetamaskAccounts();
  const walletconnectAccounts = useWalletconnectAccounts();

  const metamaskError = useMetamaskError();
  const walletconnectError = useWalletconnectError();

  const metamaskIsActivating = useMetamaskIsActivating();
  const walletconnectIsActivating = useWalletconnectIsActivating();

  const metamaskIsActive = useMetamaskIsActive();
  const walletconnectIsActive = useWalletconnectIsActive();

  const metamaskProvider = useMetamaskProvider();
  const walletconnectProvider = useWalletconnectProvider();

  const signer = useMemo(() => {
    if (metamaskProvider) return metamaskProvider.getSigner();
    if (walletconnectProvider) return walletconnectProvider.getSigner();
    return null;
  }, [metamaskProvider, walletconnectProvider]);

  const web3 = useMemo(() => ({
    chainId: metamaskChainId || walletconnectChainId,
    provider: metamaskProvider || walletconnectProvider,
    accounts: metamaskAccounts || walletconnectAccounts,
    error: metamaskError || walletconnectError,
    isActivating: metamaskIsActivating || walletconnectIsActivating,
    isActive: metamaskIsActive || walletconnectIsActive,
    signer,
    address: (metamaskAccounts || walletconnectAccounts || [])[0],
  }), [
    metamaskChainId,
    walletconnectChainId,
    metamaskAccounts,
    walletconnectAccounts,
    metamaskProvider,
    walletconnectProvider,
    walletconnectIsActive,
    metamaskIsActive,
    metamaskIsActivating,
    walletconnectIsActivating,
    metamaskError,
    walletconnectError,
    signer,
  ]);

  return (
    <Web3Context.Provider value={web3}>
      {children}
    </Web3Context.Provider>
  );
}
