import { Alert, Collapse } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";

function AlertContainer(props, ref) {
  const [alert, setAlert] = useState(null);
  
  const clear = () => setAlert(null);
  
  useImperativeHandle(ref, () => ({
    setAlert,
    clear,
  }));

  return (
    <Collapse in={!!alert}>
      <Alert
        variant="outlined"
        {...alert}
      >
        {alert?.text}
      </Alert>
    </Collapse>
  );
}

export default forwardRef(AlertContainer);
