import { getPositionAt } from "@rather-labs/vaults-unifarming-sdk";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { MAP_NETWORK_TO_RPC } from "@rather-labs/vaults-unifarming-sdk/constants";

function usePositions(positionsData) {
  const [positions, setPositions] = useState(null);

  const instantiatePositions = async (positionsData) => {
    const positionsInstances = await Promise.all(positionsData.map((position) => {
      const jsonRpcUrl = MAP_NETWORK_TO_RPC[position.network];
      const provider = new ethers.providers.JsonRpcProvider(jsonRpcUrl);
      return getPositionAt({
        positionAddress: position.address,
        protocol: position.protocol,
        farm: position.farm,
        provider,
      });
    }));
    setPositions(positionsInstances);
  };

  useEffect(() => {
    if (positionsData) instantiatePositions(positionsData);
  }, [positionsData]);

  return positions;
}

export default usePositions;
