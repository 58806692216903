import { I18nProvider } from "store/I18n";

const withI18n = (Component) => function (props) {
  return (
    <I18nProvider>
      <Component {...props} />
    </I18nProvider>
  );
};

export default withI18n;
