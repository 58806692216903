import React, { useEffect, useRef, useState } from "react";
import IERC20ABI from "@rather-labs/vaults-unifarming-sdk/contracts/IERC20.json";
import { BN } from "@rather-labs/vaults-unifarming-sdk";
import { toBN, toEthersBN } from "@rather-labs/vaults-unifarming-sdk/lib/utils";
import { ethers } from "ethers";
import { Button, Stack, TextField, Typography } from "@mui/material";
import AlertContainer from "components/AlertContainer";
import { useDialogs } from "store/Dialogs";
import DialogForm from "./DialogForm";
import ConfirmTransactionDialog from "./ConfirmTransactionDialog";

function DepositTokenDialog({
  token,
  instance,
  signer,
  onClose,
  onResult,
  wallet,
  ...rest
}) {
  const [balance, setBalance] = useState();
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const alertContainer = useRef();
  const dialogs = useDialogs();

  const { provider } = instance;

  useEffect(() => {
    (async () => {
      const IERC20 = new ethers.Contract(token.address, IERC20ABI.abi, provider);
      const balance = await IERC20.balanceOf(wallet);
      setBalance(toBN(balance).scaleDown(token.decimals));
    })();
  }, []);

  const deposit = async () => {
    const IERC20 = new ethers.Contract(token.address, IERC20ABI.abi, provider);

    const parsedAmount = BN(amount);
    if (parsedAmount.gt(balance)) {
      alertContainer.current.setAlert({ severity: "error", text: "Amount greater than balance" });
      return;
    }
    alertContainer.current.clear();

    (parsedAmount.toString());
    setLoading(true);
    const tx = await IERC20.populateTransaction.transfer(instance.address, toEthersBN(parsedAmount.scaleUp(token.decimals)));
    tx.from = wallet;

    try {
      const transaction = await signer.sendTransaction(tx);
      dialogs.create({
        DialogType: ConfirmTransactionDialog,
        txHash: transaction.hash,
        provider,
        onResult,
      });
      onClose();
    } catch (e) {
      console.log(e);
      //
    } finally {
      setLoading(false);
    }
  };

  return (
    <DialogForm
      cross
      onClose={onClose}
      title={`Deposit ${token.symbol.toUpperCase()} to your position`}
      actions={[
        <Button
          onClick={deposit}
          variant="contained"
          disabled={loading}
          color="secondary"
        >
          Deposit
        </Button>,
      ]}
      {...rest}
    >
      <Stack>
        <TextField
          type="number"
          value={amount}
          onChange={({ target: { value } }) => setAmount(value)}
          InputProps={{
            endAdornment: (
              <Button
                size="small"
                onClick={() => balance && setAmount(balance.toString())}
              >
                MAX
              </Button>
            ),
          }}
        />
        <Typography variant="caption" fontWeight="bold">
          {`Balance: ${balance ? balance.toFixed().toString() : "-"} ${token.symbol}`}
        </Typography>
      </Stack>
      <AlertContainer ref={alertContainer} />
    </DialogForm>
  );
}

export default DepositTokenDialog;
