import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import ErrorAnimationData from "lottie-files/error.json";
import SuccessAnimationData from "lottie-files/success.json";
import { chopString } from "utils";
import { Collapse, Grow, IconButton, LinearProgress, Stack, Tooltip, Typography } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import DialogForm from "./DialogForm";

const defaultOptions = {
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function ConfirmTransactionDialog({
  onClose,
  onResult,
  txHash,
  provider,
  explorer,
  ...rest
}) {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    provider.waitForTransaction(txHash)
      .then((receipt) => {
        if (onResult) onResult(receipt.status === 1);
        setStatus(receipt.status);
      });
  }, []);

  return (
    <DialogForm
      cross
      open
      onClose={onClose}
      title="Processing Transaction"
      subtitle={(
        <Stack direction="row" spacing={1}>
          <Typography variant="body2">
            {`Tx Hash: ${chopString(txHash, 40)}`}
          </Typography>
          <Tooltip title="View in explorer">
            <IconButton
              size="small"
              onClick={() => window.open(`${explorer}/tx/${txHash}`, "_blank")}
            >
              <OpenInNew fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      {...rest}
    >
      <Collapse in={status === null} mountOnEnter>
        <LinearProgress />
      </Collapse>
      <Grow in={status === 1} mountOnEnter unmountOnExit>
        <Stack
          spacing={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Lottie
            options={{
              ...defaultOptions,
              animationData: SuccessAnimationData,
            }}
            speed={0.5}
            height={100}
            width={300}
            isClickToPauseDisabled
            isStopped={false}
            isPaused={false}
          />
          <Typography>
            Transaction succeeded
          </Typography>
        </Stack>
      </Grow>
      <Grow in={status === 0} mountOnEnter unmountOnExit>
        <Stack
          spacing={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Lottie
            options={{
              ...defaultOptions,
              animationData: ErrorAnimationData,
            }}
            height={200}
            speed={0.6}
            width={300}
            isClickToPauseDisabled
            isStopped={false}
            isPaused={false}
          />
          <Typography>
            Transaction Failed
          </Typography>
        </Stack>
      </Grow>
    </DialogForm>
  );
}

export default ConfirmTransactionDialog;
