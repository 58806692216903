import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IconButton, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";

function Dialogs({
  open,
  title,
  cross,
  subtitle,
  actions,
  onClose,
  children,
  contentClasses,
  ...rest
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      onClose={onClose}
      open={open}
      {...rest}
    >
      {
        onClose && cross
        && (
          <IconButton
            sx={{
              position: "absolute",
              top: theme.spacing(1),
              right: theme.spacing(1),
            }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        )
      }
      <DialogTitle>
        <Typography variant="h6" className="bold" paragraph mr={2}>
          {title}
        </Typography>
        {subtitle && (
          <Typography color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent classes={contentClasses}>
        {children}
      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </Dialog>
  );
}

export default Dialogs;
