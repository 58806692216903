import { Box, Hidden, Stack } from "@mui/material";
import Header from "./Header";
import SideBar from "./Sidebar";

function AppLayout({ children }) {
  return (
    <Box width="100%" display="flex" flexDirection="row" height="100%">
      <Hidden smDown>
        <SideBar />
      </Hidden>
      <Stack gap={2} flexGrow={1} minWidth={0}>
        <Header />
        <Box
          sx={{
            padding: 2,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {children}
        </Box>
      </Stack>
    </Box>
  );
}

export default AppLayout;
