import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import APIEndpoints from "APIEndpoints";
import AssetComposition from "components/Overview/AssetComposition";
import { Line } from "react-chartjs-2";
import HorizontalScroll from "components/HorizontalScroll";
import Navigate from "components/Navigate";
import PositionItem from "components/PositionItem";
import RemoteList from "components/RemoteList";
import SectionTitle from "components/SectionTitle";
import useTranslation from "hooks/useTranslation";
import routes from "routes";
import PinkDivider from "components/PinkDivider";
import FadeInDelay from "components/FadeInDelay";
import YouTube from "react-youtube";
import ForwardedUnifarmingStrategyItem from "components/NewPosition/UnifarmingStrategyItem";

const labels = ["January", "February", "March", "April", "May", "June", "July"];
const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

const tutorialLinks = [
  { label: "First Steps", id: "kBJrlAHah68" },
  { label: "Funding your position", id: "A8C3NkJkAXw" },
  { label: "Assembling and Withdrawing", id: "b9C2RlbA0FE" },
];

function Overview() {
  const { t } = useTranslation();

  return (
    <Stack spacing={8}>

      <Stack spacing={2}>
        <FadeInDelay delay={250}>
          <SectionTitle>
            {t("OVERALL_STATS")}
          </SectionTitle>
        </FadeInDelay>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={4}
          >
            <FadeInDelay mountOnEnter delay={350}>
              <Paper sx={{ padding: 2, height: "100%" }}>
                <Stack spacing={4}>
                  <Box>
                    <Typography variant="h6" fontWeight="bold">
                      Asset Composition
                    </Typography>
                    <PinkDivider width="50px" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box height={320} width="100%">
                      <AssetComposition />
                    </Box>
                  </Box>
                </Stack>
              </Paper>
            </FadeInDelay>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
          >
            <FadeInDelay mountOnEnter delay={450}>
              <Paper sx={{ padding: 2, height: "100%" }}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="h6" fontWeight="bold">
                      Portfolio Value
                    </Typography>
                    <PinkDivider width="50px" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      position: "relative",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box height={320} width="100%">
                      <Line
                        style={{
                          filter: "blur(5px)",
                        }}
                        options={{
                          maintainAspectRatio: false,
                          responsive: true,
                          plugins: {
                            tooltip: true,
                            legend: {
                              display: true,
                              position: "top",
                            },
                          },
                        }}
                        data={{
                          labels,
                          datasets: [
                            {
                              label: "Dataset 1",
                              data: labels.map(() => randomIntFromInterval(-1000, 1000)),
                              borderColor: "0f1b3d",
                              backgroundColor: "#2d378c",
                            },
                            {
                              label: "Dataset 2",
                              data: labels.map(() => randomIntFromInterval(-1000, 1000)),
                              borderColor: "#4d57ab",
                              backgroundColor: "#203163",
                            },
                          ],
                        }}
                      />
                    </Box>

                    <Typography variant="h5" color="primary" fontWeight="bold" margin="auto" position="absolute">
                      {t("COMING_SOON")}
                    </Typography>
                  </Box>
                </Stack>
              </Paper>
            </FadeInDelay>
          </Grid>
        </Grid>
      </Stack>

      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <FadeInDelay delay={500}>
            <SectionTitle>
              {t("POSITIONS")}
            </SectionTitle>
          </FadeInDelay>
          <Navigate path={routes.APP.NEW_POSITION}>
            <FadeInDelay delay={550}>
              <Button
                variant="contained"
                color="secondary"
              >
                {t("NEW_POSITION")}
              </Button>
            </FadeInDelay>
          </Navigate>
        </Stack>
        <FadeInDelay mountOnEnter delay={650}>
          <div>
            <RemoteList
              Container={HorizontalScroll}
              endpoint={APIEndpoints.POSITIONS.GET}
              Item={PositionItem}
              Skeleton={ForwardedUnifarmingStrategyItem.Skeleton}
            />
          </div>
        </FadeInDelay>
      </Stack>

      <Stack spacing={2}>
        <FadeInDelay delay={700}>
          <SectionTitle>
            {t("TUTORIALS")}
          </SectionTitle>
        </FadeInDelay>
        <FadeInDelay delay={750}>
          <HorizontalScroll>
            {tutorialLinks.map((tutorial) => (
              <Stack>
                <Typography variant="overline" fontWeight="bold" color="textSecondary">
                  {tutorial.label}
                </Typography>
                <Paper sx={{
                  width: 300,
                  height: 200,
                  overflow: "hidden",
                }}
                >
                  <YouTube
                    videoId={tutorial.id}
                    opts={{
                      height: "200",
                      width: "300",
                    }}
                  />
                </Paper>
              </Stack>
            ))}
          </HorizontalScroll>
        </FadeInDelay>
      </Stack>

    </Stack>
  );
}

export default Overview;
