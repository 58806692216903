import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { forwardRef } from "react";

const useStyles = makeStyles({
  root: ({ noPadding }) => ({
    ...(noPadding ? { padding: 0 } : { }),
    height: "max-content",
    width: "auto",
    justifyContent: "left",
    textTransform: "unset",
    textAlign: "left",
    minWidth: "unset",
    "&:hover": {
      opacity: 0.9,
    },
  }),
});

function ChildrenFriendlyButton({ children, noPadding, ...rest }, ref) {
  const classes = useStyles({ noPadding });
  return (
    <Button
      ref={ref}
      classes={{
        root: classes.root,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default forwardRef(ChildrenFriendlyButton);
