import { useEffect, useState, useCallback } from "react";
import { BN } from "@rather-labs/vaults-unifarming-sdk";
import { MAP_NETWORK_TO_RPC } from "@rather-labs/vaults-beefy-sdk/constants";
import { ethers } from "ethers";
import IcERC20ABI from "lib/protocols/ironbank/abis/IcERC20.json";
import hit from "hit";

const lowPrecisionBN = BN.clone({ POW_PRECISION: 6 });

// eslint-disable-next-line camelcase
const SUPPLY_iTOKEN_ADDRESS = "0x328A7b4d538A2b3942653a9983fdA3C12c571141"; // iUSDC
// eslint-disable-next-line camelcase
const BORROW_iTOKEN_ADDRESS = "0xd528697008aC67A21818751A5e3c58C8daE54696"; // iWFTM
const DECIMALS = 18;
const BLOCKS_PER_YEAR = 31536000; // 1 block per second, same as used by Iron Banks' web

const BEEFY_ENDPOINT = "https://api.beefy.finance";
const BEEFY_URL = (timestamp) => `/apy/breakdown?_=${timestamp}`;

function useBeefyrowingStats(strat) {
  const [beefyApy, setBeefyApy] = useState(0);

  const [ironBankSupplyRate, setIronBankSupplyRate] = useState(0);
  const [ironBankBorrowRate, setIronBankBorrowRate] = useState(0);

  const [roi, setRoi] = useState(0);
  const [targetDebtRatio, setTargetDebtRatio] = useState(0);
  const [tvl, setTvl] = useState(0);

  const rateToApy = (rate) => lowPrecisionBN(BN(rate.toString()).scaleDown(DECIMALS)).plus(1).pow(BLOCKS_PER_YEAR)
    .minus(1)
    .toNumber();

  const getIronBankRates = useCallback(async () => {
    if (strat) {
      const jsonRpcUrl = MAP_NETWORK_TO_RPC[strat.network.name.toLowerCase()];
      const provider = new ethers.providers.JsonRpcProvider(jsonRpcUrl);

      const iTokenSupply = new ethers.Contract(SUPPLY_iTOKEN_ADDRESS, IcERC20ABI.abi, provider);
      const iTokenBorrow = new ethers.Contract(BORROW_iTOKEN_ADDRESS, IcERC20ABI.abi, provider);

      const supplyRate = await iTokenSupply.supplyRatePerBlock();
      const borrowRate = await iTokenBorrow.borrowRatePerBlock();

      setIronBankSupplyRate(rateToApy(supplyRate));
      setIronBankBorrowRate(rateToApy(borrowRate));
    }
  }, [strat]);

  const getBeefyData = useCallback(async () => {
    const response = await hit({ url: BEEFY_URL(Date.now() - 1000 * 60), overrideBaseUrl: BEEFY_ENDPOINT });

    if (!response.error) {
      const beefyApy = response["tomb-tomb-wftm"].totalApy;
      setBeefyApy(Number(beefyApy));
      setTvl(0);
    }
  }, []);

  const fetchProtocols = useCallback(async () => {
    await getIronBankRates();
    await getBeefyData();
  }, [getIronBankRates, getBeefyData]);

  useEffect(() => {
    fetchProtocols();
  }, [fetchProtocols]);

  useEffect(() => {
    if (strat) {
      setTargetDebtRatio(strat.metadata.debtRatio.target);
    }
  }, [strat]);

  useEffect(() => {
    if (ironBankBorrowRate && ironBankSupplyRate && targetDebtRatio && beefyApy) {
      const newRoi = (beefyApy - ironBankBorrowRate) * targetDebtRatio + ironBankSupplyRate;
      setRoi(newRoi);
    }
  }, [targetDebtRatio, beefyApy, ironBankSupplyRate, ironBankBorrowRate]);

  return { roi, tvl };
}

export default useBeefyrowingStats;
