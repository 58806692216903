import useHit from "hooks/useHit";
import APIEndpoints from "APIEndpoints";
import { useFormik } from "formik";
import { Paper, Stack, Typography } from "@mui/material";
import AlertContainer from "components/AlertContainer";
import * as yup from "yup";
import { useEffect, useRef, useState } from "react";
import FormikTextField from "components/FormikTextField";
import FormikButton from "components/FormikButton";
import SectionTitle from "components/SectionTitle";

const validationSchema = yup.object({
  capital: yup.number().required().min(0),
});

function DepositBox({ strategy }) {
  const [strategySubmitted, setStrategySubmitted] = useState(false);
  const alertContainer = useRef();
  const hit = useHit();
  const formik = useFormik({
    initialValues: {
      capital: 100,
    },
    validationSchema,
    onSubmit: async ({ capital, ownerAddress }) => {
      console.log(ownerAddress);
      const update = await hit(APIEndpoints.POSITIONS.REQUEST, { strategyId: strategy.id, capital, address: ownerAddress });
      if (!update.error) {
        alertContainer.current.setAlert({ severity: "success", text: "Deposit requested" });
        setStrategySubmitted(strategy.id);
      } else formik.setFieldError("response", update.error);
    },
  });

  useEffect(() => {
    if (strategy && strategySubmitted && strategySubmitted !== strategy.id) {
      setStrategySubmitted(null);
    }
  }, [strategy, strategySubmitted]);

  return (
    <Paper sx={{ padding: 2 }}>
      <Stack gap={1}>

        <SectionTitle variant="h6">
          Request Deposit
        </SectionTitle>
        <Typography variant="body2" paragraph color="textSecondary">
          We will review your request, deploy a smart-contract for you and contact you by email to follow your deposit
        </Typography>

        <FormikTextField
          formik={formik}
          InputProps={{
            startAdornment: "$",
          }}
          name="capital"
          label="Estimated USD amount"
          variant="outlined"
        />
        <FormikTextField
          formik={formik}
          name="ownerAddress"
          label="Liquidity Owner Address"
          variant="outlined"
        />
        <AlertContainer ref={alertContainer} />
        <FormikButton
          formik={formik}
          disabled={strategySubmitted === strategy.id}
          variant="contained"
          color="secondary"
        >
          Request
        </FormikButton>
      </Stack>

    </Paper>
  );
}

export default DepositBox;
