const THE_GRAPH = "https://api.thegraph.com";
const HYPER_GRAPH = "https://q.hg.network";

const GRAPH_HOST = {
  ethereum: THE_GRAPH,
  xdai: THE_GRAPH,
  polygon: THE_GRAPH,
  fantom: THE_GRAPH,
  bsc: THE_GRAPH,
  avalanche: THE_GRAPH,
  celo: THE_GRAPH,
  arbitrum: THE_GRAPH,
  harmony: "https://sushi.graph.t.hmny.io",
  okex: HYPER_GRAPH,
  heco: HYPER_GRAPH,
  moonriver: THE_GRAPH,
  telos: THE_GRAPH,
  kovan: THE_GRAPH,
  fuse: THE_GRAPH,
  moonbeam: THE_GRAPH,
};

export {
  GRAPH_HOST,
};
