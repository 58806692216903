import React from "react";
import { Container, Box, Typography, Stack, Divider, Grid } from "@mui/material";
import PinkDivider from "components/PinkDivider";
import AvatarPicker from "components/AvatarPicker";
import useSession from "hooks/useSession";
import { useFormik } from "formik";
import useHit from "hooks/useHit";
import * as yup from "yup";
import APIEndpoints from "APIEndpoints";
import FormikTextField from "components/FormikTextField";
import FormikResponseError from "components/FormikResponseError";
import { APP } from "routes";
import { useHistory, useLocation } from "react-router-dom";
import FormikButton from "components/FormikButton";
import ChildrenFriendlyButton from "components/ChildrenFriendlyButton";
import { GppBadOutlined, PrivacyTipOutlined, SecurityOutlined } from "@mui/icons-material";

const validationSchema = yup.object({
  name: yup.string().required(),
  newPassword: yup.string().required(),
  repeatNewPassword: yup.string().required(),
  risk: yup.number().required(),
});

const risk = [
  { risk: 1, Icon: SecurityOutlined, color: "#00AC70", label: "Risk-Averse", description: "Prioritize the safety of principal over the possibility of a higher return on their money." },
  { risk: 2, Icon: PrivacyTipOutlined, color: "#d9cb0f", label: "Risk-Neutral", description: "May understand that risk is involved, but they aren't considering it for the moment." },
  { risk: 3, Icon: GppBadOutlined, color: "#ba224d", label: "Risk-Seeking", description: "Willing to accept greater economic uncertainty in exchange for the potential of higher returns." },
];

function CompleteProfile() {
  const hit = useHit();
  const session = useSession();
  const location = useLocation();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      name: "",
      newPassword: "",
      repeatNewPassword: "",
      risk: 1,
    },
    validationSchema,
    onSubmit: async ({ name, newPassword, repeatNewPassword, risk }) => {
      if (newPassword === repeatNewPassword) {
        const update = await hit(APIEndpoints.USER.UPDATE, { name, newPassword, risk });
        if (!update.error) {
          await session.refresh();
          
          const { redirect } = location.state || {};
          const storedRedirect = localStorage.getItem("storedRedirect");
          localStorage.removeItem("storedRedirect");
    
          history.push(redirect || storedRedirect || APP.OVERVIEW);
        } else formik.setFieldError("response", update.error);
      } else {
        formik.setFieldError("repeatNewPassword", "Passwords doesn't match");
      }
    },
  });
  
  return (
    <Container
      maxWidth="sm"
      sx={{
        padding: 2,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
      }}
    >
        
      <Stack spacing={3} width="100%">
        <Stack spacing={1} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" fontWeight="bold">
            Complete Your Profile
          </Typography>
          <PinkDivider variant="middle" sx={{ width: 50 }} />
        </Stack>

        <Stack spacing={4}>
          <Stack spacing={2}>
            <AvatarPicker sx={{ alignSelf: "center" }} />
            <FormikTextField
              formik={formik}
              variant="outlined"
              name="name"
              label="Full Name"
            />
          </Stack>

          <Divider variant="middle" sx={{ width: "30%", alignSelf: "center" }} />
          
          <Stack spacing={2}>
            <Stack>
              <Typography>
                What type of investor are you?
              </Typography>
              <Typography variant="body2">
                You will receive curated strategies based on your profile
              </Typography>
            </Stack>
            <Grid container>
              {risk.map(({ Icon, color, label, risk, description }) => (
                <Grid item xs={12} sm={4}>
                  <ChildrenFriendlyButton
                    variant={formik.values.risk === risk && "contained"}
                    onClick={() => formik.setFieldValue("risk", risk)}
                    color="inherit"
                  >
                    <Stack textAlign="center" alignItems="center">
                      <Icon
                        fontSize="large"
                        sx={{ color }}
                      />
                      <Typography variant="body2">
                        {label}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {description}
                      </Typography>
                    </Stack>
                  </ChildrenFriendlyButton>
                </Grid>
              ))}
            </Grid>
          </Stack>
          <Divider variant="middle" sx={{ width: "30%", alignSelf: "center" }} />

          <Stack spacing={2}>
            <FormikTextField
              formik={formik}
              variant="outlined"
              name="newPassword"
              type="password"
              label="New password"
            />
            <FormikTextField
              formik={formik}
              variant="outlined"
              type="password"
              name="repeatNewPassword"
              label="Repeat new password"
            />
          </Stack>

        </Stack>
           
        <Box>
          <FormikResponseError formik={formik} />
          <FormikButton
            formik={formik}
            color="secondary"
            variant="contained"
            size="large"
          >
            Continue
          </FormikButton>
        </Box>
      </Stack>
    </Container>
  );
}

export default CompleteProfile;
