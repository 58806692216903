module.exports = {
  APP: {
    OVERVIEW: "/overview",
    NEW_POSITION: "/new-position",
    UNIFARMING: "/unifarming/:positionId",
    BEEFYROWING: "/beefy/:positionId",
  },
  LANDING: {

  },
  AUTH: {
    COMPLETE_PROFILE: "/complete-profile",
    RESET_PASSWORD: "/reset-password",
    SIGNIN: "/sign-in",
  },
};
