import { Stack, Typography } from "@mui/material";
import { forwardRef } from "react";

function SectionTitle({ children, Icon, style, ...rest }, ref) {
  return (
    <Stack ref={ref} style={style} direction="row" alignItems="center" spacing={1}>
      {
        Icon
          ? <Icon color="primary" />
          : (
            <img
              alt="dots"
              src="/dots-mini.png"
              style={{
                height: 11,
                width: 26,
              }}
            />
          )
      }
      <Typography variant="h5" fontWeight="bold" {...rest}>
        {children}
      </Typography>
    </Stack>
  );
}

export default forwardRef(SectionTitle);
