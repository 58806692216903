import { SessionProvider } from "store/Session";

const withSession = (Component) => function (props) {
  return (
    <SessionProvider>
      <Component {...props} />
    </SessionProvider>
  );
};

export default withSession;
