import { useCallback, useState } from "react";
import { Divider, Grid, Stack } from "@mui/material";
import RecommendedStrategies from "components/NewPosition/RecommendedStrategies";
import AllStrategies from "components/NewPosition/AllStrategies";
import OverallInfo from "components/NewPosition/OverallInfo";
import HistoricalData from "components/NewPosition/HistoricalData";
import DepositBox from "components/NewPosition/DepositBox";

function NewPosition() {
  const [strategy, setStrategy] = useState(null);

  const onElements = (list) => {
    const params = new URLSearchParams(window.location.search);
    const stratId = params.get("strat");
    const selectedStrat = list.find((strat) => strat.id === stratId);

    if (!strategy) setStrategy(selectedStrat || list[0] || null);
  };

  const onClick = useCallback((data) => {
    setStrategy(data);
  }, []);

  return (
    <Stack gap={4}>
      <RecommendedStrategies
        onElements={onElements}
        onClick={onClick}
        selectedStrategy={strategy}
      />
      <AllStrategies
        onClick={onClick}
        selectedStrategy={strategy}
      />

      <Divider variant="middle" />
      {strategy && (
        <Stack gap={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <OverallInfo strategy={strategy} />
            </Grid>
            <Grid item xs={12} md={7}>
              <HistoricalData strategy={strategy} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <DepositBox strategy={strategy} />
            </Grid>
          </Grid>
        </Stack>
      )}

    </Stack>
  );
}

export default NewPosition;
