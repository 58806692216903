import { Box, useTheme } from "@mui/material";
import { forwardRef } from "react";

function HorizontalScroll({ children, className, ...rest }, ref) {
  const theme = useTheme();
  return (
    <Box sx={{
      display: "flex",
      overflowX: "auto",
      overflowY: "hidden",
    }}
    >
      <Box
        ref={ref}
        sx={{
          display: "flex",
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          "& > *": {
            marginRight: theme.spacing(2),
          },
        }}
        {...rest}
      >
        {children}
      </Box>
    </Box>
  );
}

export default forwardRef(HorizontalScroll);
