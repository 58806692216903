import React, { useRef, useState } from "react";
import { Edit } from "@mui/icons-material";
import { Avatar, Badge } from "@mui/material";
import ChildrenFriendlyButton from "./ChildrenFriendlyButton";

function AvatarPicker({ initialAvatar, width = 80, height = 80, ...rest }) {
  const [avatar, setAvatar] = useState(initialAvatar);
  const fileBox = useRef(null);

  const onChangePicture = () => {
    fileBox.current.click();
  };
  
  const onChangeFile = async () => {
    const file = document.getElementById("file").files[0];
    const reader = new FileReader();
    reader.onload = () => setAvatar(reader.result);
    reader.readAsDataURL(file);
    // if (error) toast.error("Error while changing profile picture");
    // const { error } = await users.updateAvatar(file);
    // else {
    //   toast.success("Profile picture changed");
    // }
  };
      
  return (
    <ChildrenFriendlyButton onClick={onChangePicture} {...rest}>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        badgeContent={<Edit color="action" />}
      >
        <Avatar
          sx={{
            width,
            height,
          }}
          src={avatar}
        />
      </Badge>

      <input
        type="file"
        id="file"
        ref={(_ref) => { fileBox.current = _ref; }}
        style={{ display: "none" }}
        onChange={onChangeFile}
      />
    </ChildrenFriendlyButton>
  );
}

export default AvatarPicker;
