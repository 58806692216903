import { Stack } from "@mui/material";
import ChildrenFriendlyButton from "components/ChildrenFriendlyButton";
import Navigate from "components/Navigate";
import routes from "routes";
// import { useHistory, useLocation } from "react-router-dom";

// const links = [
//   { label: "Overview", link: "/overview", Icon: Home },
//   { label: "Information", link: "/info", Icon: Info },
// ];

function Sidebar() {
  return (
    <Stack
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        backgroundColor: "secondary.dark",
        width: 90,
        padding: 3,
      }}
    >
      <Stack spacing={1}>
        <Navigate path={routes.APP.OVERVIEW}>
          <ChildrenFriendlyButton noPadding color="secondary">
            <img alt="logo" src="/logo.svg" />
          </ChildrenFriendlyButton>
        </Navigate>
      </Stack>
      {/* <Stack spacing={2}>
        {links.map(({ label, link, Icon }) => (
          <Tooltip title={label} arrow placement={'left'} >
            <Button
            color="secondary"
             variant={ link === pathname ? "contained" : "outlined" }
              onClick={() => history.push(link)}
              >
              <Icon />
            </Button>
          </Tooltip>
        )) }
      </Stack> */}
    </Stack>

  );
}

export default Sidebar;
