import { useEffect, useState, useMemo } from "react";
import { HistoryToggleOff } from "@mui/icons-material";
import { Paper, Stack, Box } from "@mui/material";
import SectionTitle from "components/SectionTitle";
import { Chart as ChartJS, Tooltip } from "chart.js";
import { Line } from "react-chartjs-2";
import APIEndpoints from "APIEndpoints";
import useResource from "hooks/useResource";

const formatAxisDate = (dateString) => {
  const date = new Date(dateString);
  // Months from 1-12
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  // const year = date.getUTCFullYear();

  return `${month}/${day}`;
};

const formatHoverDate = (dateString) => new Date(dateString).toDateString();

ChartJS.register(Tooltip);

function HistoricalData({ strategy }) {
  const { data } = useResource(APIEndpoints.STATS.GET_BY_STRATEGY_ID(strategy.id));
  const [stats, setStats] = useState({});

  const axisLabels = useMemo(() => Object.keys(stats).map((key) => formatAxisDate(stats[key].createdAt)), [stats]);
  const hoverLabels = useMemo(() => Object.keys(stats).map((key) => formatHoverDate(stats[key].createdAt)), [stats]);

  useEffect(() => {
    if (data) setStats(data);
  }, [data]);

  return (
    <Paper sx={{ padding: 2, height: "100%" }}>
      <Stack spacing={2}>
        <Box>
          <SectionTitle Icon={HistoryToggleOff} variant="h6">
            Historical Data
          </SectionTitle>
        </Box>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box height={320} width="100%">
            <Line
              options={{
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  tooltip: {
                    enabled: true,
                    callbacks: {
                      title(context) {
                        return hoverLabels[context[0].parsed.x];
                      },
                      label(context) {
                        const { label } = context.dataset;
                        const { y } = context.parsed;
                        return `${label}: ${y.toFixed(1)}%`;
                      },
                    },
                  },
                  title: {
                    display: false,
                    // display: true,
                    // text: "APR",
                    // font: {
                    //   family: "Poppins",
                    //   size: 20,
                    // },
                  },
                  legend: {
                    display: false,
                  },
                },
                interaction: {
                  intersect: false,
                },
                scales: {
                  x: {
                    display: true,
                    ticks: {
                      callback: (val) => axisLabels[val],
                    },
                  },
                  y: {
                    display: true,
                    title: {
                      display: true,
                      text: "APR",
                      font: {
                        family: "Poppins",
                        size: 20,
                      },
                    },
                    ticks: {
                      callback: (val) => `${val}%`,
                    },
                  },
                },
              }}
              data={{
                // labels: Object.keys(stats).map((key) => formatDate(stats[key].createdAt)),
                labels: Object.keys(stats),
                datasets: [
                  {
                    label: "APR",
                    data: Object.keys(stats).map((key) => (stats[key].values.roiPerYear * 100)),
                    borderColor: "0f1b3d",
                    backgroundColor: "#2d378c",
                  },
                ],
              }}
            />
          </Box>
        </Box>
      </Stack>
    </Paper>
  );
}

export default HistoricalData;
